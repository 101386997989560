import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Tooltip,
} from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { patchData } from "../../controllers/vectordbController";
import useGetOrgDetail from "../../hooks/useGetOrgDetail";

const NoToolTip = ({
  item,
  handleDelete,
  setInputValue,
  setToggle,
  handlePinUpdate,
  disableNavigation,
}) => {
  const navigate = useNavigate();
  const { _orgId, _threadId } = useParams();
  return (
    <div
      onClick={() => {
        if (disableNavigation) {
          return;
        }
        setInputValue("");
        navigate(`/${_orgId}/audyt-ai/${item.payload.chatId}`);
      }}
      className={`w-full text-sm p-2 h-10 rounded-lg flex items-center ${
        _threadId === item.payload.chatId ? "bg-[#F3F3F3]" : ""
      } hover:bg-[#F3F3F3] justify-between ${
        disableNavigation ? " cursor-default" : " cursor-pointer"
      }`}
      variant="light"
    >
      <div className="flex items-center gap-2">
        {item.payload.pinned && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-pin text-default-500"
          >
            <path d="M12 17v5" />
            <path d="M9 10.76a2 2 0 0 1-1.11 1.79l-1.78.9A2 2 0 0 0 5 15.24V16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-.76a2 2 0 0 0-1.11-1.79l-1.78-.9A2 2 0 0 1 15 10.76V7a1 1 0 0 1 1-1 2 2 0 0 0 0-4H8a2 2 0 0 0 0 4 1 1 0 0 1 1 1z" />
          </svg>
        )}
        <span>
          {item?.payload?.title?.slice(0, 20)}
          {item?.payload?.title.length > 20 && "..."}
        </span>
      </div>

      <Dropdown isDisabled={disableNavigation}>
        <DropdownTrigger>
          <Button variant="light" size="sm" isIconOnly>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
              />
            </svg>
          </Button>
        </DropdownTrigger>
        <DropdownMenu>
          <DropdownItem
            variant="flat"
            onClick={() => handlePinUpdate(!item.payload.pinned)}
            startContent={
              item.payload.pinned ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-pin-off"
                >
                  <path d="M12 17v5" />
                  <path d="M15 9.34V7a1 1 0 0 1 1-1 2 2 0 0 0 0-4H7.89" />
                  <path d="m2 2 20 20" />
                  <path d="M9 9v1.76a2 2 0 0 1-1.11 1.79l-1.78.9A2 2 0 0 0 5 15.24V16a1 1 0 0 0 1 1h11" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-pin text-black"
                >
                  <path d="M12 17v5" />
                  <path d="M9 10.76a2 2 0 0 1-1.11 1.79l-1.78.9A2 2 0 0 0 5 15.24V16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-.76a2 2 0 0 0-1.11-1.79l-1.78-.9A2 2 0 0 1 15 10.76V7a1 1 0 0 1 1-1 2 2 0 0 0 0-4H8a2 2 0 0 0 0 4 1 1 0 0 1 1 1z" />
                </svg>
              )
            }
          >
            {item.payload.pinned ? "Unpin" : "Pin"}
          </DropdownItem>
          <DropdownItem
            onClick={() => setToggle(true)}
            variant="flat"
            startContent={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                />
              </svg>
            }
          >
            Edit
          </DropdownItem>
          <DropdownItem
            onClick={() => handleDelete(item.payload.id, item.payload.chatId)}
            startContent={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-4 text-red-500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                />
              </svg>
            }
            variant="flat"
            color="danger"
          >
            Delete
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

const ThreadList = ({
  item,
  handleDelete,
  setInputValue,
  setThreads,
  disableNavigation,
}) => {
  const shouldUseTooltip = item?.payload?.title?.length > 20;
  const [title, setTitle] = useState(item?.payload?.title);
  const [toggle, setToggle] = useState(false);
  const inputRef = useRef();
  const { OrgDetail: spaceInfo } = useGetOrgDetail();
  const handleTitleUpdate = async (event) => {
    if (event.key === "Escape") {
      setTitle(item.payload.title);
      setToggle(false);
      return;
    }
    if (title.trim() !== "" && event.key === "Enter") {
      if (title === item.payload.title) {
        setToggle(false);
        return;
      }
      try {
        await patchData(
          { title },
          item.payload.id,
          spaceInfo[0]?.attributes?.SpaceName
        );
        setThreads((prev) =>
          prev.map((it) =>
            it.payload.id === item.payload.id ? { ...it, title } : it
          )
        );
        item.payload.title = title;
        setToggle(false);
      } catch (e) {
        console.log(e);
      }
    } else {
      return;
    }
  };
  const handlePinUpdate = async (val) => {
    try {
      await patchData(
        { pinned: val },
        item.payload.id,
        spaceInfo[0]?.attributes?.SpaceName
      );
      setThreads((prev) =>
        prev.map((it) =>
          it.payload.id === item.payload.id ? { ...it, pinned: val } : it
        )
      );
      item.payload.pinned = val;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const handleEscPress = (event) => {
      if (event.key === "Escape") {
        setTitle(item.payload.title);
        setToggle(false);
      }
    };

    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setTitle(item.payload.title);
        setToggle(false);
      }
    };

    if (toggle) {
      window.addEventListener("keydown", handleEscPress);
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      window.removeEventListener("keydown", handleEscPress);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggle, item.payload.title]);
  return (
    <>
      {toggle ? (
        <div className="w-full p-2 h-12 rounded-lg flex items-center bg-[#F3F3F3]">
          <Input
            ref={inputRef}
            variant="bordered"
            onKeyDown={handleTitleUpdate}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full"
            size="sm"
          />
        </div>
      ) : (
        <Tooltip
          placement="left"
          content={item?.payload?.title}
          isDisabled={!shouldUseTooltip}
          key={item.payload.chatId}
        >
          <div>
            <NoToolTip
              disableNavigation={disableNavigation}
              handlePinUpdate={handlePinUpdate}
              setToggle={setToggle}
              item={item}
              handleDelete={handleDelete}
              setInputValue={setInputValue}
            />
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default ThreadList;
