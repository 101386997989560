import {
  Button,
  DatePicker,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Select,
  SelectItem,
  Slider,
  Switch,
  Textarea,
} from "@nextui-org/react";
import { ChangeDateDDToYYYY, DateFormatorDD } from "../../../utils/helper";
import { FaChevronDown } from "react-icons/fa";
import { CreateNewEntryButton } from "../../../utils/helper";
import { useEffect, useRef, useState } from "react";
import { parseDate } from "@internationalized/date";
import { uploadFile } from "../../../controllers/APIController";
import InfoToolTip from "../../../components/utils/InfoToolTip";
import FieldInputTooltip from "../../../components/utils/FieldInputTooltip";

export const TextInput = ({
  data,
  value,
  moreInfo,
  orgFrameworkDetails,
  validationErrors,
  onChangeHandler,
  cssStyle = "mb-12",
}) => {
  const inputHandler = (event) => {
    onChangeHandler(data?.title, event.target.value, data?.type);
  };
  const length = data?.title?.length || 0;
  cssStyle = length > 100 ? "mb-16" : cssStyle;
  return (
    <div className={`flex gap-2 ${cssStyle}`}>
      <Input
        type="text"
        isReadOnly={data?.isDisable === true ? true : false}
        isRequired={data?.required || false}
        label={data?.title || "Title"}
        // maxLength={data?.char_limit}
        placeholder={data?.placeholder || " "}
        variant="faded"
        labelPlacement="outside"
        value={value}
        onChange={inputHandler}
        startContent={data?.startContent}
        isInvalid={validationErrors[data?.title] && data?.required}
        errorMessage={validationErrors[data?.title]}
      />
      {moreInfo && (
        <div className="flex h-max">
          <InfoToolTip
            data={
              <FieldInputTooltip
                data={moreInfo}
                selectedFramework={data?.framework}
                orgFrameworkDetails={orgFrameworkDetails}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export const PasswordInput = ({
  data,
  value,
  moreInfo,
  orgFrameworkDetails,
  validationErrors,
  onChangeHandler,
  cssStyle = "mb-16",
}) => {
  const length = data?.title?.length || 0;
  cssStyle = length > 100 ? "mb-16" : "mb-12";
  return (
    <div className={`flex gap-2 ${cssStyle}`}>
      <Input
        type="password"
        isRequired={data?.required || false}
        label={data?.title || "Title"}
        maxLength={data?.char_limit}
        placeholder={data?.placeholder || " "}
        variant="faded"
        labelPlacement="outside"
        value={value}
        onChange={(event) => {
          onChangeHandler(data?.title, event.target.value, data?.type);
        }}
        isInvalid={validationErrors[data?.title] && data?.required}
        errorMessage={validationErrors[data?.title]}
      />
      {moreInfo && (
        <div className="flex h-max">
          <InfoToolTip
            data={
              <FieldInputTooltip
                data={moreInfo}
                selectedFramework={data?.framework}
                orgFrameworkDetails={orgFrameworkDetails}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export const IntegerInput = ({
  data,
  value,
  moreInfo,
  orgFrameworkDetails,
  validationErrors,
  onChangeHandler,
  cssStyle = "mb-12",
}) => {
  const length = data?.title?.length || 0;
  cssStyle = length > 100 ? "mb-16" : "mb-12";
  return (
    <div className={`flex gap-2 items-start ${cssStyle}`}>
      <Input
        type="number"
        isRequired={data?.required || false}
        label={data?.title || "Title"}
        min={data?.min_val}
        max={data?.max_val}
        placeholder="0"
        variant="faded"
        labelPlacement="outside"
        value={value && value}
        onChange={(event) => {
          onChangeHandler(data?.title, +event.target.value, data?.type);
        }}
        isInvalid={validationErrors[data?.title] && data?.required}
        errorMessage={validationErrors[data?.title]}
      />
      {moreInfo && (
        <div className="flex h-max">
          <InfoToolTip
            data={
              <FieldInputTooltip
                data={moreInfo}
                selectedFramework={data?.framework}
                orgFrameworkDetails={orgFrameworkDetails}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export const SliderInput = ({
  data,
  value,
  moreInfo,
  orgFrameworkDetails,
  validationErrors,
  onChangeHandler,
  cssStyle = "mb-16",
}) => {
  const length = data?.title?.length || 0;
  cssStyle = length > 100 ? "mb-16" : "mb-16";
  return (
    <div className={`flex gap-2 ${cssStyle}`}>
      <Slider
        isRequired={data?.required || false}
        label={data?.title || "Title"}
        maxValue={data?.max_val || 100}
        minValue={data?.min_val || 0}
        step={data?.step_val || 1}
        defaultValue={value || 0}
        value={value || 0}
        onChange={(value) => {
          onChangeHandler(data?.title, value, data?.type);
        }}
        isInvalid={validationErrors[data?.title] && data?.required}
        errorMessage={validationErrors[data?.title]}
      />
      {moreInfo && (
        <div className="flex h-max">
          <InfoToolTip
            data={
              <FieldInputTooltip
                data={moreInfo}
                selectedFramework={data?.framework}
                orgFrameworkDetails={orgFrameworkDetails}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export const BooleanInput = ({
  data,
  value,
  moreInfo,
  orgFrameworkDetails,
  validationErrors,
  onChangeHandler,
  cssStyle = "mb-12",
}) => {
  const length = data?.title?.length || 0;
  cssStyle = length > 100 ? "mb-16" : "mb-12";

  return (
    <div className={`flex gap-2 justify-between ${cssStyle}`}>
      <div className="flex items-center justify-between gap-4 mb-16">
        <p className="">{data?.title}</p>
        <Switch
          label={data?.title}
          defaultSelected={value === "true"}
          isSelected={value === "true" ? true : false}
          color="primary"
          className=""
          labelPlacement="outside"
          onChange={(event) =>
            onChangeHandler(
              data?.title,
              event.target.checked.toString(),
              data?.type
            )
          }
        ></Switch>
      </div>
      {moreInfo && (
        <div className="flex h-max">
          <InfoToolTip
            data={
              <FieldInputTooltip
                data={moreInfo}
                selectedFramework={data?.framework}
                orgFrameworkDetails={orgFrameworkDetails}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export const TextAreaInput = ({
  data,
  value,
  moreInfo,
  orgFrameworkDetails,
  validationErrors,
  onChangeHandler,
  cssStyle = "mb-12",
}) => {
  const length = data?.title?.length || 0;
  cssStyle = length > 100 ? "mb-16" : cssStyle;
  return (
    <div className={`flex gap-2 ${cssStyle}`}>
      <Textarea
        key={data?.title}
        // className={cssStyle}
        isRequired={data?.required || false}
        label={data?.title || "Title"}
        value={value}
        // maxLength={data?.char_limit}
        placeholder={data?.placeholder || " "}
        variant="faded"
        labelPlacement="outside"
        onChange={(event) => {
          onChangeHandler(data?.title, event.target.value, data?.type);
        }}
        isInvalid={validationErrors[data?.title] && data?.required}
        errorMessage={validationErrors[data?.title]}
      />
      {moreInfo && (
        <div className="flex h-max">
          <InfoToolTip
            data={
              <FieldInputTooltip
                data={moreInfo}
                selectedFramework={data?.framework}
                orgFrameworkDetails={orgFrameworkDetails}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export const DateInput = ({
  data,
  value,
  moreInfo,
  orgFrameworkDetails,
  validationErrors,
  onChangeHandler,
  cssStyle = "mb-16",
}) => {
  const length = data?.title?.length || 0;
  cssStyle = length > 100 ? "mb-16" : "mb-16";
  // console.log(value,value!==null && value!== undefined && value !== "" ? parseDate(ChangeDateDDToYYYY(value)) : null);
  const [position, setPosition] = useState({});
  const datePickerRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const rect = datePickerRef.current.getBoundingClientRect();
      if (rect.bottom > window.innerHeight) {
        setPosition({ top: "auto", bottom: 0 });
      } else {
        setPosition({});
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className={`flex gap-2 justify-between ${cssStyle}`}>
      <div ref={datePickerRef} style={position} className="w-full">
        <DatePicker
          label={data.title}
          labelPlacement="outside"
          isRequired={data?.required || false}
          onChange={(value) => {
            onChangeHandler(data?.title, DateFormatorDD(value), data?.type);
          }}
          // value={value}
          value={
            value && value !== "" && value !== "null"
              ? parseDate(ChangeDateDDToYYYY(value))
              : null
          }
          isInvalid={validationErrors[data?.title] && data?.required}
          errorMessage={validationErrors[data?.title]}
        />
      </div>
      {moreInfo && (
        <div className="flex h-max">
          <InfoToolTip
            data={
              <FieldInputTooltip
                data={moreInfo}
                selectedFramework={data?.framework}
                orgFrameworkDetails={orgFrameworkDetails}
              />
            }
          />
        </div>
      )}
    </div>
  );
};
export const DropDownInput = ({
  data,
  value,
  moreInfo,
  orgFrameworkDetails,
  validationErrors,
  onChangeHandler,
  buttonCSS = "mb-16",
  cssStyle = "mb-12",
}) => {
  const length = data?.title?.length || 0;
  cssStyle = length > 100 ? "mb-16" : buttonCSS;
  // console.log(value, validationErrors[data.title]);
  const valueList = data?.value || data?.values;
  return (
    <div className={`flex gap-2 w-full ${cssStyle}`}>
      <div className="flex flex-col gap-2 text-sm w-full">
        <p>
          <span
            className={`${
              validationErrors[data?.title] && data?.required
                ? "text-[#f76497]"
                : ""
            }`}
          >
            {data?.title}
          </span>
          {data?.required && <span style={{ color: "#f76497" }}> *</span>}
        </p>
        <Dropdown className="">
          <DropdownTrigger
            className="w-full"
            isInvalid={validationErrors[data?.title] && data?.required}
            errorMessage={validationErrors[data?.title]}
          >
            <Button variant="faded" className={`w-full`}>
              <div className="w-full flex justify-between">
                <div className="flex justify-start w-full items-start">
                  {value === "" ? "" : value}
                </div>
                <div className="flex justify-end w-full items-end">
                  <FaChevronDown />
                </div>
              </div>
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Static Actions"
            className="w-144 custom-dropdown-menu"
            selectionMode={data?.ismultiselect ? "multiple" : "single"}
            closeOnSelect={data?.ismultiselect ? false : true}
            selectedKeys={value?.trim() !== "" && new Set(value?.split(", "))}
            onSelectionChange={(event) => {
              onChangeHandler(
                data?.title,
                Array.from(event).join(", ").replaceAll("_", " "),
                data?.type
              );
            }}
          >
            {valueList?.map((item) => (
              <DropdownItem key={item.name} value={item.name}>
                {item.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
      {moreInfo && (
        <div className="flex h-max">
          <InfoToolTip
            data={
              <FieldInputTooltip
                data={moreInfo}
                selectedFramework={data?.framework}
                orgFrameworkDetails={orgFrameworkDetails}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export const RelationDropDownInput = ({
  data,
  value,
  moreInfo,
  orgFrameworkDetails,
  validationErrors,
  onChangeHandler,
  onClickHandler,
  buttonCSS = "mb-16",
  cssStyle = "mb-12",
}) => {
  const length = data?.title?.length || 0;
  cssStyle = length > 100 ? "mb-16" : buttonCSS;
  const metricIDList = data?.relatedto.split(",");
  const createEntryButtonList = metricIDList.map((metricId) => {
    return {
      id: "button",
      name: (
        <CreateNewEntryButton
          name={metricId}
          metricName={data?.metricname}
          onClickHandler={onClickHandler}
        />
      ),
    };
  });
  let newRawValue =
    data?.values === undefined
      ? createEntryButtonList
      : [...createEntryButtonList, ...data?.values];
  // row[1].values = [...newRawValue];
  return (
    <div className={`flex gap-2 w-full ${cssStyle}`}>
      <div className="flex flex-col gap-2 text-sm w-full">
        <p>
          {data?.title}
          {data?.required && <span style={{ color: "#f76497" }}> *</span>}
        </p>
        <Dropdown>
          <DropdownTrigger
            isInvalid={validationErrors[data?.title] && data?.required}
            errorMessage={validationErrors[data?.title]}
            className="w-full"
          >
            <Button className="" variant="faded">
              <div className="w-full flex justify-between">
                <div className="flex justify-start w-full items-start">
                  {value === "" ? "" : value}
                </div>
                <div className="flex justify-end w-full items-end">
                  <FaChevronDown />
                </div>
              </div>
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Static Actions"
            className="w-144 custom-dropdown-menu"
            selectionMode={data?.ismultiselect ? "multiple" : "single"}
            closeOnSelect={data?.ismultiselect ? false : true}
            // selectedKeys={"a"}
            onSelectionChange={(event) => {
              onChangeHandler(
                data?.title,
                Array.from(event).join(", ").replaceAll("_", " "),
                data?.type
              );
            }}
          >
            {newRawValue?.map((item) => (
              <DropdownItem key={item.name} className="my-1">
                {item.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
      {moreInfo && (
        <div className="flex h-max">
          <InfoToolTip
            data={
              <FieldInputTooltip
                data={moreInfo}
                selectedFramework={data?.framework}
                orgFrameworkDetails={orgFrameworkDetails}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export const FileInput = ({
  data,
  value,
  moreInfo,
  orgFrameworkDetails,
  validationErrors,
  onChangeHandler,
  cssStyle = "mb-16",
  inputfiledId,
}) => {
  const length = data?.title?.length || 0;
  cssStyle = length > 100 ? "mb-16" : "mb-12";

  return (
    <div>
      {validationErrors[data?.title] && (
        <p className="text-[#f31b66] text-sm p-1">
          {validationErrors[data?.title]}
        </p>
      )}
      <label
        htmlFor={inputfiledId}
        className={"flex flex-col items-start gap-2" + cssStyle}
      >
        <div className={`mb-1 `}>
          {value?.url || data?.title}
          <span>
            {data?.required && <span style={{ color: "#f31b66" }}> *</span>}
          </span>
        </div>
        <div className="flex flex-col items-center gap-2 text-sm border-solid border-2 px-4 py-2 border-danger text-danger hover:text-white hover:bg-danger  rounded-lg max-w-max cursor-pointer transition ease-in duration-150">
          {"Upload File"}
        </div>
      </label>

      <input
        type="file"
        id={inputfiledId}
        accept={data?.acceptfieldtype?.join(",") || "*"}
        className="mb-16 hidden"
        onChange={(event) => {
          onChangeHandler(data?.title, event.target.files[0], data?.type);
        }}
      />
    </div>
  );
};

export const CustomFrequencyInput = ({
  value,
  validationErrors,
  onChangeHandler,
}) => {
  return (
    <Input
      type="number"
      className="mb-16"
      isRequired
      label={"Choose Custom Days"}
      min={0.01}
      step="0.01"
      placeholder="0"
      variant="faded"
      labelPlacement="outside"
      value={value && value}
      onChange={(event) => {
        onChangeHandler(+event.target.value);
      }}
      // isInvalid={validationErrors[data?.title] && data?.required}
      // errorMessage={validationErrors[data?.title]}
    />
  );
};

export const MultiSelectInput = ({
  data,
  valuesList,
  value,
  onChangeHandler,
  validationErrors = {},
  cssStyle = "mb-8",
}) => {
  return (
    <Select
      isRequired={data?.required}
      label={data?.title}
      placeholder={data?.placeholder || " "}
      variant="faded"
      selectionMode="multiple"
      labelPlacement="outside"
      className={cssStyle}
      isInvalid={validationErrors[data?.title] && data?.required}
      errorMessage={validationErrors[data?.title]}
      selectedKeys={new Set(value)}
      onSelectionChange={(event) =>
        onChangeHandler(data?.title, Array.from(event), data?.type)
      }
    >
      {valuesList?.map((item) => (
        <SelectItem key={item.id} value={item.id} className="py-2">
          {item.name}
        </SelectItem>
      ))}
    </Select>
  );
};

export const changeHandler = async (
  title,
  value,
  type,
  formdata,
  setFormData
) => {
  if (type === "file") {
    let fileData = new FormData();
    fileData.append("uploaded_file", value);

    try {
      const data = await uploadFile(fileData);
      // const data = await response.json();
      setFormData({
        ...formdata,
        [title]: { value: data[0].id, url: data[0].url },
      });
    } catch (error) {
      console.error("Upload error!", error);
    }
  } else if (type === "boolean") {
    if (value === "") {
      setFormData({ ...formdata, [title]: "false" });
    } else {
      setFormData({ ...formdata, [title]: value });
    }
  } else setFormData({ ...formdata, [title]: value });
};

// export const Input = ({ data, validationErrors, changeHandler }) => {
//   return (
//     <>
//       <DatePicker
//         className="mb-16"
//         label={data?.title}
//         labelPlacement="outside"
//         isRequired={data?.isRequired || false}
//         onChange={(value) => {
//           changeHandler(data?.title, DateFormator(value), data?.type);
//         }}
//         isInvalid={validationErrors[data?.title] && data?.isRequired}
//       errorMessage={validationErrors[data?.title]}
//       />
//     </>
//   );
// };
