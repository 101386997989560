import { Spinner } from "@nextui-org/react";
import React from "react";
import { grid } from "ldrs";

grid.register();

const LoadingPage = ({
  children,
  isLoading = true,
  alterElement = (
    <div className="flex items-center justify-center">
      <l-grid size="100" speed="1.5" color="black"></l-grid>
    </div>
  ),
}) => {
  return <>{isLoading ? alterElement : children}</>;
};

export default LoadingPage;

{
  /* <Spinner
  size="lg"
  color="warning"
  label="Loading..."
  className="flex items-center justify-center mt-28"
/> */
}
