import { Code } from '@nextui-org/react';
import React, { useState } from 'react'
import AnomalyDrawer from './AnomalyDrawer';

const Anomaly = ({threadResp}) => {
  const [isOpen,setIsOpen] = useState(false);
  
  return (<>
    <AnomalyDrawer isOpen={isOpen} setIsOpen={setIsOpen} threadResp={threadResp} />
    <div className="gap-2 pl-12 pt-4 pb-2" >This looks like a <span><Code color="secondary" size="sm">{threadResp?.anomaly}</Code></span>{" "}. Would you like it to your anomly list? <span onClick={() => setIsOpen(true)} className=" text-blue-500 hover:underline cursor-pointer" >View Anomaly</span> </div>
    </>
  )
}

export default Anomaly
