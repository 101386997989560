import {
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "@nextui-org/react";
import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { StringLimit } from "../../controllers/APIController.js";

const SearchModal = ({ isOpen, setIsOpen, threads }) => {
  const [val, setVal] = useState("");
  const navigate = useNavigate();
  const { _spaceid } = useParams();
  const filteredThread = useMemo(() => {
    return threads.filter((a) =>
      a?.payload?.title?.toLowerCase()?.includes(val?.toLowerCase())
    );
  }, [threads, val]);
  return (
    <Modal
      className="h-[70vh]"
      size="2xl"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <ModalContent>
        <ModalHeader className="pt-8" >
          <Input
            startContent={
              <lord-icon
                src="https://cdn.lordicon.com/kkvxgpti.json"
                trigger="hover"
                colors={`primary:"#000000"`}
                style={{ height: "20px", width: "20px" }}
              ></lord-icon>
            }
            placeholder="Seach Thread..."
            value={val}
            onChange={(e) => setVal(e.target.value)}
          />
        </ModalHeader>
        <ModalBody className="flex h-full flex-col gap-4 overflow-y-auto">
          <div
            onClick={() => {
              navigate(`/${_spaceid}/haya`); 
              setIsOpen(false)
            }}
            className="w-full h-12 p-4 flex gap-4 items-center cursor-pointer rounded-lg hover:bg-[#F3F3F3] "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
            <div>Start a new chat</div>
          </div>
          {filteredThread.map((it) => (
            <div onClick={() => {navigate(`/${_spaceid}/haya/${it.payload.chatId}`); setIsOpen(false)} } className="w-full h-12 p-4 flex items-center cursor-pointer rounded-lg hover:bg-[#F3F3F3] ">
              {StringLimit(it.payload.title, 60)}
            </div>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SearchModal;
