import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";

import { BarChart, Bar } from "recharts";

import { PieChart, Pie, Cell, Tooltip as PieTooltip } from "recharts";

import {
  ScatterChart,
  Scatter,
  XAxis as ScatterXAxis,
  YAxis as ScatterYAxis,
  CartesianGrid as ScatterCartesianGrid,
  Tooltip as ScatterTooltip,
} from "recharts";

import { LineChart, Line } from "recharts";

import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";

const ChartGenerator = ({ chartType, data }) => {
  switch (chartType) {
    case "area":
      return (
        <ResponsiveContainer width="100%" height={"100%"}>
          <AreaChart
            data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis dataKey="name" />

            <YAxis />

            <Tooltip />

            <Area
              type="monotone"
              dataKey="uv"
              stackId="1"
              stroke="#8884d8"
              fill="#8884d8"
            />

            <Area
              type="monotone"
              dataKey="pv"
              stackId="1"
              stroke="#82ca9d"
              fill="#82ca9d"
            />

            <Area
              type="monotone"
              dataKey="amt"
              stackId="1"
              stroke="#ffc658"
              fill="#ffc658"
            />
          </AreaChart>
        </ResponsiveContainer>
      );

    case "bar":
      return (
        <ResponsiveContainer width="100%" height={"100%"}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis dataKey="name" />

            <YAxis />

            <Tooltip />

            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      );

    case "pie":
      return (
        <ResponsiveContainer width="100%" height={"100%"}>
          <PieChart>
            <Pie data={data} dataKey="value" outerRadius={100} fill="#8884d8">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>

            <PieTooltip />
          </PieChart>
        </ResponsiveContainer>
      );

    case "scatter":
      return (
        <ResponsiveContainer width="100%" height={"100%"}>
          <ScatterChart>
            <ScatterCartesianGrid />

            <ScatterXAxis dataKey="x" name="X Axis" />

            <ScatterYAxis dataKey="y" name="Y Axis" />

            <ScatterTooltip />

            <Scatter name="Scatter" data={data} fill="#8884d8" />
          </ScatterChart>
        </ResponsiveContainer>
      );

    case "line":
      return (
        <ResponsiveContainer width="100%" height={"100%"}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis dataKey="name" />

            <YAxis />

            <Tooltip />

            <Line
              type="linear"
              dataKey="Emission"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            >
              {/* <LabelList dataKey="Emission" position="bottom" /> */}
            </Line>

            <Line type="monotone" dataKey="pv" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
      );

    case "radar":
      return (
        <ResponsiveContainer width="100%" height={"100%"}>
          <RadarChart data={data}>
            <PolarGrid />

            <PolarAngleAxis dataKey="subject" />

            <PolarRadiusAxis />

            <Radar
              name="Radar"
              dataKey="A"
              stroke="#8884d8"
              fill="#8884d8"
              fillOpacity={0.6}
            />
          </RadarChart>
        </ResponsiveContainer>
      );

    default:
      return <div>Invalid chart type</div>;
  }
};

export default ChartGenerator;
