import {
  Avatar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Chip,
} from "@nextui-org/react";
import React from "react";
import { ProgessColors } from "../../utils/constant";
import { usePDF } from "@react-pdf/renderer";

const TemplateCard = ({ index = 0, DataList, setDataList, Component }) => {
  const [instance, updateInstance] = usePDF({ document: <Component /> });

  const colorValue = ProgessColors[index % 4];

  const changeHandler = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setDataList({
        ...DataList,
        TemplateIndex: index,
        TemplateComponent: Component,
      });
    } else {
      setDataList({ ...DataList, TemplateIndex: null });
    }
  };

  const clickHandler = (urlLink) => {
    if (!urlLink) return;
    console.log(urlLink);

    window.open(urlLink, "_blank");
  };

  let val = "";
  if (instance.loading) val = "loading";
  if (instance.error) val = "Something went wrong";
  else val = instance.url;

  return (
    <>
      <Card
        className="py-2 w-[350px] h-[350px] border border-divider overflow-auto hover:opacity-90 cursor-pointer"
        shadow="none"
      >
        <CardHeader className="flex items-center px-4 gap-4 py-2">
          <Avatar color={colorValue} size="lg" />
          <div className="flex w-[80%] items-center justify-between">
            <p className="text-xl font-medium">Template 1</p>
            <Checkbox
              color={colorValue}
              isSelected={DataList["TemplateIndex"] === index}
              onChange={changeHandler}
            />
          </div>
        </CardHeader>
        <CardBody className="overflow-visible py-2">
          <div className="text-lg">
            This template provides a structured approach to creating ESG reports, ensuring all key metrics are addressed and formatted for clarity. Use this as a starting point for your sustainability disclosures.
          </div>
        </CardBody>
        <CardFooter className="flex items-center justify-end px-4">
          {/* <PDFDownloadLink document={<Component />} fileName="somename.pdf">
            {({ blob, url, loading, error }) => {
              if (!loading) {
                console.log(url);

                pdfRef.current = url;
              }
              return loading ? "Loading document..." : "Download now!";
            }}
          </PDFDownloadLink> */}
          <div onClick={() => clickHandler(val)}>
            <Chip variant="flat" color={colorValue} className="cursor-pointer">
              {val ? "View" : "Loading"}
            </Chip>
          </div>
        </CardFooter>
      </Card>
    </>
  );
};

export default TemplateCard;
