import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Switch,
  Textarea,
} from "@nextui-org/react";

import React, { useEffect, useState } from "react";
import { CategoryList } from "../../utils/constant";
import { FaChevronDown } from "react-icons/fa";
import { getDataWithFilterUrl } from "../../controllers/APIController";

const initalValidationError = {
  title: "",
  relatedTo: "",
  metricName: "",
  valueList: "",
  linkUrl: "",
  Framework: "",
};
const fileTypes = [
  { name: "All Images", key: "image/*" },
  { name: "All Audio", key: "audio/*" },
  { name: "All Videos", key: "video/*" },
  { name: "Only PDF", key: "application/pdf" },
  {
    name: "Only PPT",
    key: "application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation",
  },
  {
    name: "Only Excel",
    key: "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
];

const EditAddModal = ({
  onSubmitHandler,
  id,
  isOpen,
  onOpen,
  onOpenChange,
  fieldType,
  item,
  frameWorkList,
  frameWorkDetails,
  category,
}) => {
  console.log(item);

  const [title, setTitle] = useState("");
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(100);
  const [stepValue, setStepValue] = useState("1");
  const [charLimit, setCharLimit] = useState("");
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [acceptFieldType, setAcceptFieldType] = useState([]);

  const [required, setRequired] = useState(false);
  const [relatedTo, setRelatedTo] = useState("");
  const [metricName, setMetricName] = useState("");
  const [valuesList, setValuesList] = useState("");
  const [relatedToList, setRelatedToList] = useState([]);
  const [linkUrl, setLinkUrl] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    ...initalValidationError,
  });

  const [frameWorkData, setFrameWorkData] = useState([]);

  const [referenceList, setReferenceList] = useState({});

  const [overviewList, setOverviewList] = useState({});
  const [guidanceList, setGuidanceList] = useState({});

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const validationHandler = () => {
    let isValid = true;
    const newValidationError = { ...initalValidationError };
    if (title === "") {
      newValidationError["title"] = "Title can't be empty";
      isValid = false;
    }
    if (relatedTo === "" && item?.name == "Relation") {
      newValidationError["relatedTo"] = "relatedTo can't be empty";
      isValid = false;
    }
    if (valuesList === "" && item.name === "Drop Down") {
      newValidationError["valueList"] = "valuesList can't be empty";
      isValid = false;
    }
    if (metricName === "" && item?.name === "Relation") {
      newValidationError["metricName"] = "metricName can't be empty";
      isValid = false;
    }
    if (linkUrl === "" && item.name == "link") {
      newValidationError["linkUrl"] = "Url can't be empty";
      isValid = false;
    }
    if (
      category?.toLowerCase() !== "reference data" &&
      frameWorkData?.length === 0
    ) {
      newValidationError["Framework"] = "select at least one framework";
      isValid = false;
    }
    setValidationErrors({ ...newValidationError });

    return isValid;
  };
  const submitHandler = (onClose) => {
    const isValid = validationHandler();
    if (isValid === false) return;
    const fieldInfo = {
      id: item.id,
      Title: title,
      Required: required,
      Reference: referenceList,
      Overview: overviewList,
      Guidance: guidanceList,
      framework: frameWorkData,
    };
    if (fieldType === "text" || fieldType === "textarea") {
      onSubmitHandler({
        ...fieldInfo,
        Type: item.Type,
        name: item.name,
        char_limit: +charLimit,
      });
    } else if (fieldType === "integer") {
      onSubmitHandler({
        ...fieldInfo,
        Type: item.Type,
        name: item.name,
        min_val: +minValue,
        max_val: +maxValue,
      });
    } else if (fieldType === "slider") {
      onSubmitHandler({
        ...fieldInfo,
        Type: item.Type,
        name: item.name,
        min_val: +minValue,
        max_val: +maxValue,
        step_val: stepValue,
      });
    } else if (item?.name === "Relation") {
      onSubmitHandler({
        ...fieldInfo,
        Type: item.Type,
        relatedTo: relatedTo,
        metricName: metricName,
        name: item.name,
        isMultiSelect: isMultiSelect,
      });
    } else if (item?.name === "Drop Down") {
      const values = valuesList.split(",").map((item, index) => {
        return {
          id: index,
          name: item,
        };
      });

      onSubmitHandler({
        ...fieldInfo,
        Type: item.Type,
        values: values,
        name: item.name,
        isMultiSelect: isMultiSelect,
      });
    } else if (item.name === "link") {
      onSubmitHandler({
        ...fieldInfo,
        link: linkUrl,
        Type: "link",
        name: "link",
      });
    } else if (item.name === "file" || fieldType === "file") {
      onSubmitHandler({
        ...fieldInfo,
        Type: item.Type,
        name: item.name,
        acceptFieldType: acceptFieldType,
      });
    } else {
      onSubmitHandler({
        ...fieldInfo,
        Type: item.Type,
        name: item.name,
      });
    }

    onClose();
  };

  const handleKeyDown = (event, onClose) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default action of form submission on Enter key press
      submitHandler(onClose);
    }
  };

  useEffect(() => {
    if (metricName === "") return;

    const filteredUrl = "filters[Category][$eqi]=" + metricName.trim();

    getDataWithFilterUrl("metrics", filteredUrl)
      .then((data) => {
        let response = data.data;

        const list = response.map((item) => {
          return {
            id: item.id,
            name: item?.attributes?.Name,
          };
        });
        setRelatedToList(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [metricName]);

  useEffect(() => {
    setValidationErrors({ ...initalValidationError });
  }, [isOpen]);

  useEffect(() => {
    setTitle(item?.Title || "");
    setRequired(item?.Required || false);
    setRelatedTo(item?.relatedTo || "");
    setMetricName(item?.metricName || "");
    setValuesList(item?.values?.map((data) => data.name).join(",") || "");
    setLinkUrl(item.link);
    setMaxValue(item?.max_val || 0);
    setMinValue(item?.min_val || 100);
    setStepValue(item?.step_val || "1");
    setCharLimit(item?.char_limit || "");
    setIsMultiSelect(item?.isMultiSelect || false);
    setAcceptFieldType(item?.acceptFieldType || "");
    setFrameWorkData(item?.framework || []);
    setOverviewList(item?.Overview || {});
    setGuidanceList(item?.Guidance || {});
    setReferenceList(item?.Reference || {});
    console.log(item, item?.Reference, item?.framework);
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="2xl">
        <ModalContent>
          {(onClose) => (
            <div
              onKeyDown={(event) => handleKeyDown(event, onClose)}
              tabIndex="0"
              className="outline-none"
            >
              <ModalHeader className="flex flex-col gap-1">
                Update {item?.name} Field
              </ModalHeader>
              <ModalBody>
                <div className="overflow-y-scroll max-h-[400px] hide-scrollbar">
                  <Input
                    type="text"
                    className="mb-8"
                    isRequired
                    // isDisabled
                    label="Title"
                    placeholder=" "
                    variant="faded"
                    labelPlacement="outside"
                    value={title || ""}
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                    isInvalid={validationErrors["title"] !== ""}
                    errorMessage={validationErrors["title"]}
                  />

                  {/* These input field for text and textarea Input Type  */}
                  {(item?.Type === "text" || item?.Type === "textarea") && (
                    <>
                      <Input
                        type="number"
                        className="mb-8"
                        label="Char Limit"
                        placeholder=" "
                        variant="faded"
                        labelPlacement="outside"
                        value={charLimit}
                        onChange={(event) => {
                          setCharLimit(event.target.value);
                        }}
                      />
                    </>
                  )}

                  {/* These input field for Integer Input Type  */}
                  {(item?.Type === "integer" || item?.Type === "slider") && (
                    <>
                      <Input
                        type="number"
                        className="mb-8"
                        label="Min Value"
                        placeholder={minValue}
                        variant="faded"
                        labelPlacement="outside"
                        value={minValue}
                        onChange={(event) => {
                          setMinValue(event.target.value);
                        }}
                      />
                      <Input
                        type="number"
                        className="mb-8"
                        label="Max Value"
                        placeholder={maxValue}
                        variant="faded"
                        labelPlacement="outside"
                        value={maxValue}
                        onChange={(event) => {
                          setMaxValue(event.target.value);
                        }}
                      />
                    </>
                  )}

                  {item?.Type === "slider" && (
                    <Input
                      type="text"
                      className="mb-8"
                      label="Step Value"
                      placeholder=" "
                      variant="faded"
                      labelPlacement="outside"
                      value={stepValue}
                      onChange={(event) => {
                        setStepValue(event.target.value);
                      }}
                    />
                  )}
                  {/* These input field for Relation Input Type  */}
                  {item?.Type === "relation" && (
                    <>
                      <div className="flex flex-col gap-2 text-sm w-full">
                        <p
                          className={`${
                            validationErrors["metricName"] && "text-[#f76497]"
                          }`}
                        >
                          Category Name <span style={{ color: "red" }}>*</span>
                        </p>
                        {validationErrors["metricName"] && (
                          <p className="text-[#f76497] mt-0">
                            {validationErrors["metricName"]}
                          </p>
                        )}
                        <Dropdown>
                          <DropdownTrigger className="w-full">
                            <Button className="mb-8" variant="faded">
                              <div className="w-full flex justify-between">
                                <div className="flex justify-start w-full items-start">
                                  {metricName}
                                </div>
                                <div className="flex justify-end w-full items-end">
                                  <FaChevronDown />
                                </div>
                              </div>
                            </Button>
                          </DropdownTrigger>
                          <DropdownMenu
                            aria-label="Static Actions"
                            className="w-144 custom-dropdown-menu"
                            selectionMode="single"
                            name="categoryName"
                            onSelectionChange={(event) => {
                              setMetricName(
                                Array.from(event)
                                  .join(", ")
                                  .replaceAll("_", " ")
                              );
                              setRelatedTo("");
                            }}
                          >
                            {CategoryList?.map((item) => (
                              <DropdownItem key={item.name}>
                                {item.name}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </div>

                      {/* RelatedTo field DropDown  */}

                      <div className="flex flex-col gap-2 text-sm w-full">
                        <Select
                          label="Related To"
                          placeholder="Choose single/multiple field"
                          variant="faded"
                          selectionMode="multiple"
                          labelPlacement="outside"
                          className="my-8"
                          isRequired
                          selectedKeys={relatedTo.split(",")}
                          onSelectionChange={(event) =>
                            setRelatedTo(
                              Array.from(event).join(",").replaceAll("_", " ")
                            )
                          }
                        >
                          {relatedToList?.map((item) => (
                            <SelectItem
                              key={item.name}
                              value={item.name}
                              className="py-2"
                            >
                              {item.name}
                            </SelectItem>
                          ))}
                        </Select>
                      </div>
                    </>
                  )}

                  {/* These fields for specific DropDown Field  */}

                  {item?.Type === "dropdown" && (
                    <Textarea
                      className="mb-8"
                      label={"Enter Values Option by Space-Separate"}
                      key="1"
                      variant="faded"
                      labelPlacement="outside"
                      isRequired
                      value={valuesList}
                      placeholder="value1, values2, values3"
                      onChange={(event) => setValuesList(event.target.value)}
                      isInvalid={validationErrors["valueList"] !== ""}
                      errorMessage={validationErrors["valueList"]}
                    />
                  )}
                  {/* These fields for specific File Field  */}

                  {fieldType === "file" && (
                    <div className="flex flex-col gap-2 text-sm w-full">
                      <Select
                        label="Files Accepted"
                        placeholder=" "
                        variant="faded"
                        selectionMode="multiple"
                        labelPlacement="outside"
                        className="my-8"
                        isRequired
                        selectedKeys={new Set(acceptFieldType)}
                        onSelectionChange={(event) => {
                          setAcceptFieldType(Array.from(event));
                        }}
                      >
                        {fileTypes?.map((item) => (
                          <SelectItem
                            key={item.key}
                            value={item.name}
                            className="py-2"
                          >
                            {item.name}
                          </SelectItem>
                        ))}
                      </Select>
                    </div>
                  )}

                  {item.Type === "link" && (
                    <Input
                      type="text"
                      className="mb-8"
                      isRequired
                      label="Link"
                      placeholder=" "
                      variant="faded"
                      labelPlacement="outside"
                      value={linkUrl || ""}
                      onChange={(event) => {
                        setLinkUrl(event.target.value);
                      }}
                      isInvalid={validationErrors["linkUrl"] !== ""}
                      errorMessage={validationErrors["linkUrl"]}
                    />
                  )}

                  {/* Framework Field */}
                  {category?.toLowerCase() !== "reference data" && (
                    <>
                      <div className="mt-12">
                        <Select
                          isRequired
                          label="Select FrameWork"
                          placeholder="Select any FrameWorks"
                          variant="faded"
                          selectionMode="multiple"
                          labelPlacement="outside"
                          className="mb-8"
                          errorMessage={validationErrors["Framework"]}
                          isInvalid={validationErrors["Framework"] !== ""}
                          selectedKeys={frameWorkData}
                          onSelectionChange={(event) =>
                            setFrameWorkData(Array.from(event))
                          }
                        >
                          {frameWorkList?.map((item) => (
                            <SelectItem
                              key={item.id}
                              value={item.name}
                              className="py-2"
                            >
                              {item.name}
                            </SelectItem>
                          ))}
                        </Select>
                      </div>

                      <>
                        {frameWorkData?.map((id) => {
                          return (
                            <Input
                              type="text"
                              className="my-12"
                              variant="faded"
                              label={`Reference for ${frameWorkDetails[+id]}`}
                              labelPlacement="outside"
                              placeholder=" "
                              value={referenceList[frameWorkDetails[+id]]}
                              onChange={(event) =>
                                setReferenceList({
                                  ...referenceList,
                                  [frameWorkDetails[+id]]: event.target.value,
                                })
                              }
                            />
                          );
                        })}
                      </>
                      <>
                        {frameWorkData?.map((id) => {
                          return (
                            <Input
                              type="text"
                              className="my-12"
                              variant="faded"
                              value={overviewList[frameWorkDetails[+id]]}
                              label={`Overview for ${frameWorkDetails[+id]}`}
                              labelPlacement="outside"
                              placeholder=" "
                              onChange={(event) =>
                                setOverviewList({
                                  ...overviewList,
                                  [frameWorkDetails[+id]]: event.target.value,
                                })
                              }
                            />
                          );
                        })}
                      </>
                      <>
                        {frameWorkData?.map((id) => {
                          return (
                            <Input
                              type="text"
                              className="my-12"
                              variant="faded"
                              value={guidanceList[frameWorkDetails[+id]]}
                              label={`Guidance for ${frameWorkDetails[+id]}`}
                              labelPlacement="outside"
                              placeholder=" "
                              onChange={(event) =>
                                setGuidanceList({
                                  ...guidanceList,
                                  [frameWorkDetails[+id]]: event.target.value,
                                })
                              }
                            />
                          );
                        })}
                      </>
                    </>
                  )}
                  {item.Type !== "link" && (
                    <div className="flex items-center">
                      <div className="flex-1">
                        <p className="mb-2">{"Make Required"}</p>
                        <Switch
                          isSelected={required}
                          color="primary"
                          className="mb-4"
                          label="Make Required"
                          labelPlacement="outside"
                          onChange={(event) => setRequired((pre) => !pre)}
                        >
                          {required ? "On" : "Off"}
                        </Switch>
                      </div>
                      {(item?.Type === "dropdown" ||
                        item?.Type === "relation") && (
                        <div className="flex-1">
                          <p className="mb-2">{"Multi-Select"}</p>
                          <Switch
                            isSelected={isMultiSelect}
                            color="primary"
                            className="mb-4"
                            // label="Make Required"
                            labelPlacement="outside"
                            onChange={(event) =>
                              setIsMultiSelect((pre) => !pre)
                            }
                          >
                            {isMultiSelect ? "Yes" : "No"}
                          </Switch>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" variant="light" onPress={onClose}>
                  Cancel
                </Button>
                <Button color="danger" onClick={() => submitHandler(onClose)}>
                  Update
                </Button>
              </ModalFooter>
            </div>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
export default EditAddModal;
