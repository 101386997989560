import React, { useEffect, useState } from "react";
import {
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import Header from "../Header/Header";
import { Divider, useDisclosure } from "@nextui-org/react";
// import { ThemeProvider as NextThemesProvider } from 'next-themes';

import { getOnedata } from "../../controllers/APIController";
import useGetAllOrganization from "../../hooks/useGetAllOrganization";
import OrgPopUp from "../CreateOrg/OrgPopUp";
import {
  firstNavBarList,
  secondNavBarList,
  thirdNavbarList,
} from "../../constant/Navbar/navbar.constant";
import NavbarFooter from "./NavbarFooter";

const RenderNavList = ({ List, currentActiveUrl, orgId }) => {
  return (
    <nav className="mt-6 flex-1">
      <ul className="flex flex-col gap-1">
        {List &&
          List?.map((data, index) => {
            const Verified = `/${currentActiveUrl}` === data.path;
            return (
              <li key={index}>
                <NavLink
                  className="rounded-md"
                  to={`/${orgId}${data.path}`}
                  id={data.id}
                >
                  <span
                    className={`flex p-1 items-center gap-2 rounded-md px-2 text-md hover:bg-grey-400 text-black hover:text-foreground' ${
                      Verified ? "text-foreground bg-grey-400" : ""
                    }`}
                  >
                    <div>{data.icon}</div>
                    {data.title}
                  </span>
                </NavLink>
              </li>
            );
          })}
      </ul>
    </nav>
  );
};
const Navbar = () => {
  const [UserDetails, setUserDetails] = useState(null);
  const JWT_Testing = JSON.parse(sessionStorage.getItem("userData"));
  // const [ThemeSelecter, setThemeSelecter] = useState(false);
  const { _orgId } = useParams();

  const { _orgId: currOrg } = useParams();
  const { OrgList, isLoading: isLoadingOrgList, err } = useGetAllOrganization();

  const Location = useLocation();
  const Navigate = useNavigate();

  const URLPath = Location.pathname.split("/");

  // const theme=sessionStorage.getItem("Theme");

  useEffect(() => {
    if (JWT_Testing === null) {
      sessionStorage.setItem("lastRoute", Location.pathname);
      Navigate("/sign-in");
    }
  }, [Navigate]);

  useEffect(() => {
    getOnedata("users", JWT_Testing && JWT_Testing.user.id)
      .then((data) => setUserDetails(data))
      .catch((error) => error);
  }, []);

  useEffect(() => {
    console.log(OrgList, isLoadingOrgList, err, currOrg);

    if (!isLoadingOrgList && !OrgList?.includes(currOrg)) {
      console.log(OrgList, currOrg);
      console.log("**");
      Navigate("*");
      // window.location.reload();
    }
  }, [OrgList, currOrg, isLoadingOrgList]);

  return (
    <div className={`flex flex-row w-full max-w-full h-full overflow-hidden`}>
      <nav>
        <div
          className={`flex flex-col justify-between w-64 dark:bg-root h-screen flex-shrink-0 border-r border-divider bg-inherit px-4 dark:border-gray-600 md:flex overflow-auto custom-scrollbar`}
        >
          {/* Navbar top */}
          <OrgPopUp List={OrgList} currOrg={_orgId} />

          {/* Navbar Mid */}

          <RenderNavList
            List={firstNavBarList}
            currentActiveUrl={URLPath[2]}
            orgId={_orgId}
          />
          <Divider className="mt-8" />
          <RenderNavList
            List={secondNavBarList}
            currentActiveUrl={URLPath[2]}
            orgId={_orgId}
          />
          <Divider className="mt-8" />
          <RenderNavList
            List={thirdNavbarList}
            currentActiveUrl={URLPath[2]}
            orgId={_orgId}
          />
          {/* Navbar Footer */}

          <NavbarFooter UserDetails={UserDetails} _orgId={_orgId} />
        </div>
      </nav>

      <div className="w-full flex flex-col">
        <Header />
        {/* <div className="h-[calc(100vh-60px)] overflow-auto custom-scrollbar">
          <div className="flex flex-col gap-2 mx-auto max-w-7xl py-8 h-full">
            <Outlet />
          </div>
        </div> */}
        <div className="h-[calc(100vh-60px)] overflow-y-auto custom-scrollbar p-6 px-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
