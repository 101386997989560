import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Pagination,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { StringLimit } from "../../controllers/APIController";
import {
  DeleteOnedata,
  getDataWithFilterUrl,
} from "../../controllers/APIController";

import {
  CopyTableItem,
  DeleteTableItem,
  SearchInput,
} from "../../utils/helper";
import LoadingPage from "../../components/Loader/LoadingPage";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import NullData from "../../components/ErrorPage/NullData";
import TableSkeleton from "../../components/Skeleten/TableSkeleton";

const MetricsBuilder = () => {
  const navigate = useNavigate();
  const { _orgId } = useParams();
  const [isLoading, setisLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const [metrics, setMetrics] = useState([]);
  const [reRender, setReRender] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged?.user?.id;
  const userRole = UserLogged && UserLogged?.user?.userRole;

  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const handleNavigate = () => {
    navigate(`/${_orgId}/metrics-builder/create`);
  };

  const deleteFormHandler = async (id) => {
    console.log(id);
    try {
      const result = await DeleteOnedata("metrics", id);
      console.log(result);
      setReRender((pre) => !pre);
    } catch (error) {
      console.log(error);
    }
  };

  const createDuplicateHandler = (id) => {
    navigate(`create?id=${id}`);
  };
  useEffect(() => {
    getDataWithFilterUrl("metrics", orgFilterUrl)
      .then((data) => {
        const list = data.data;
        list.sort((a, b) => {
          if (
            a?.attributes?.Name.trim().toLowerCase() <
            b?.attributes?.Name.trim().toLowerCase()
          ) {
            return -1;
          }
          if (
            a?.attributes?.Name.trim().toLowerCase() >
            b?.attributes?.Name.trim().toLowerCase()
          ) {
            return 1;
          }
          return 0;
        });

        setMetrics(list);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reRender]);

  const headers = [
    "Metric Name",
    "Category",
    "Frequency",
    "Description",
    "Actions",
  ];

  const handleNavigateToMetric = (id) => {
    navigate(`/${_orgId}/metrics-builder/${id}`);
  };

  // Handle filter selection changes
  const handleFilterSelection = (key) => {
    setSelectedFilter(key);
  };

  // Filter metrics based on the search term and selected filter
  const filteredMetrics = metrics.filter((item) => {
    const name = item?.attributes?.Name || "";
    const description = item?.attributes?.Description || "";
    const category = item?.attributes?.Category || "";
    const matchesSearchTerm =
      name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      description.toLowerCase().includes(searchTerm.toLowerCase()) ||
      category.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilter =
      selectedFilter === "" ||
      category.toLowerCase() === selectedFilter.toLowerCase();
    const isNotSurvey = category.toLowerCase() !== "survey";
    return matchesSearchTerm && matchesFilter && isNotSurvey;
  });

  const ItemPerPage = 20;
  const totalPage = Math.ceil(filteredMetrics.length / ItemPerPage);

  return (
    <BreadCrumbsPage>
      <div >
        <div className="text-3xl font-medium">Metrics Builder</div>
        <div className="my-8">
          Create or edit ESG metrics that would support your ESG Reporting.
        </div>
        <div
          className={`flex justify-end cursor-pointer ${
            userRole !== "Admin" ? "pointer-events-none opacity-50" : ""
          }`}
        >
          <Button color="danger" onClick={handleNavigate}>
            Create a new metric
          </Button>
        </div>

        <LoadingPage isLoading={isLoading} alterElement={<TableSkeleton />}>
          <div className="mt-4">
            <SearchInput
              searchText={searchTerm}
              setSearchText={setSearchTerm}
              placeholderText={"Search for an ESG metric"}
            />

            <div className="mt-4 flex justify-end">
              <Dropdown>
                <DropdownTrigger>
                  <Button
                    size="sm"
                    endContent={<FontAwesomeIcon icon={faFilter} />}
                    variant="bordered"
                  >
                    Filter
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Dropdown Variants"
                  onAction={handleFilterSelection}
                >
                  <DropdownItem key="">Clear Filters</DropdownItem>
                  <DropdownItem
                    key="Reference Data"
                    color="primary"
                    variant="flat"
                  >
                    Reference Data
                  </DropdownItem>
                  <DropdownItem
                    key="Environment"
                    color="success"
                    variant="flat"
                  >
                    Environment
                  </DropdownItem>
                  <DropdownItem key="Social" variant="flat" color="warning">
                    Social
                  </DropdownItem>
                  <DropdownItem key="Governance" variant="flat" color="danger">
                    Governance
                  </DropdownItem>
                  <DropdownItem
                    key="Vendor Details"
                    color="warning"
                    variant="flat"
                  >
                    Vendor Details
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <>
              <div className="mt-8">
                <Table removeWrapper selectionMode="single">
                  <TableHeader>
                    {headers.map((header, headerIndex) => (
                      <TableColumn key={headerIndex}>{header}</TableColumn>
                    ))}
                  </TableHeader>
                  <TableBody
                    emptyContent={
                      <NullData
                        title={`No metric found. Click on "create" to make a new metric`}
                      />
                    }
                  >
                    {filteredMetrics.map((item, index) => {
                      const lowerPageLimit = (currentPage - 1) * ItemPerPage;
                      const upperPageLimit = currentPage * ItemPerPage;
                      if (index < lowerPageLimit || index >= upperPageLimit)
                        return null;

                      return (
                        <TableRow
                          key={item.id}
                          onClick={() => handleNavigateToMetric(item.id)}
                          className="cursor-pointer"
                        >
                          <TableCell>
                            {StringLimit(item?.attributes?.Name, 50) || "--"}
                          </TableCell>
                          <TableCell>
                            {item?.attributes?.Category || "--"}
                          </TableCell>
                          <TableCell>
                            {item?.attributes?.Frequency || "--"}
                          </TableCell>
                          <TableCell>
                            {(item.attributes.Description &&
                              StringLimit(item?.attributes?.Description, 80)) ||
                              "--"}
                          </TableCell>
                          <TableCell className="flex items-center gap-4">
                            <CopyTableItem
                              userRole={userRole}
                              deleteHandler={createDuplicateHandler}
                              item={item}
                            />
                            <DeleteTableItem
                              userRole={userRole}
                              deleteHandler={deleteFormHandler}
                              item={item}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
              {filteredMetrics.length > 0 && (
                <div className="w-full mt-8 flex items-center justify-center">
                  <Pagination
                    showControls
                    total={totalPage}
                    color="secondary"
                    page={currentPage}
                    onChange={setCurrentPage}
                  />
                </div>
              )}
            </>
          </div>
        </LoadingPage>
      </div>
    </BreadCrumbsPage>
  );
};

export default MetricsBuilder;
