import React from "react";

const SingleFrameworkMetricInfo = ({ title, value }) => {
  return (
    <div className="flex flex-col max-w-72 text-xs my-2">
      <p className="font-medium">{title || "Unknown"}</p>
      <p className="text-gray">{value || `Not found`}</p>
    </div>
  );
};

const MetricTooltip = ({ data, orgFrameworkDetails, selectedFramework }) => {
  const getFrameworkName = (id) => {
    let name = "Unknown";
    orgFrameworkDetails?.forEach((item) => {
      console.log(item?.id, +id, item?.id === +id);
      if (item?.id === +id) {
        name = item?.attributes?.Name;
      }
    });
    return name;
  };

  return (
    <div className="flex flex-col px-2 py-2 min-w-[200px] max-h-[250px] overflow-auto hide-scrollbar">
      {selectedFramework?.map((id) => {
        const name = getFrameworkName(id);
        return (
          <SingleFrameworkMetricInfo
            title={name}
            value={data?.[name] || "--"}
          />
        );
      })}
    </div>
  );
};

export default MetricTooltip;
