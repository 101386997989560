import {
  Button,
  Chip,
  Code,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Switch,
  Tab,
  Tabs,
  Textarea,
  Tooltip,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { colorPicker } from "../../controllers/basicControllers";
import { postData } from "../../controllers/vectordbController";
import { Info } from "lucide-react";
import useGetOrgDetail from "../../hooks/useGetOrgDetail";
import useGetUserDetail from "../../hooks/useGetUserInfo";
import { getOnedata } from "../../controllers/APIController";


const AddModal = ({ isOpen, setIsOpen, threadResp }) => {
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [dataProduct, setDataProduct] = useState(null);

  const { OrgDetail: spaceInfo } = useGetOrgDetail();
  const { userDetails: userInfo } = useGetUserDetail();

  const [isPublic, setIsPublic] = useState(true);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (tags.includes(tagInput.trim()) || tagInput.trim() === "") {
        return;
      } else {
        setTags((prev) => [...prev, tagInput.trim()]);
        setTagInput("");
      }
    } else {
      return;
    }
  };
  const handleSubmit = async () => {
    if (name.trim() === "" || desc.trim() === "") {
      return;
    }
    try {
      const formData = {
        name: name.trim(),
        description: desc.trim(),
        sql: [threadResp?.chart?.sql_statement],
        tags: tags,
        dataproduct_id: String(threadResp?.data_product),
        type: threadResp?.anomaly,
        userId: userInfo.id,
        public: isPublic,
      };
      const id = Date.now();
      await postData(
        id,
        formData,
        spaceInfo[0]?.attributes?.SpaceName,
        "anomaly"
      );
      setIsOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetchDataProduct = async () => {
      try {
        const resp = await getOnedata(
          "collections",
          Number(threadResp?.data_product)
        );
        setDataProduct(resp.data);
        if (resp.data.attributes.Public === false) {
          setIsPublic(false);
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (threadResp?.data_product) {
      fetchDataProduct();
    }
  }, [threadResp]);
  useEffect(() => {
    setName("");
    setDesc("");
    setTags([]);
    setTagInput("");
  }, [isOpen]);
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalContent>
        <ModalHeader>Add Anomaly</ModalHeader>
        <ModalBody className="flex flex-col gap-4">
          <Input
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Name"
            labelPlacement="outside"
          />
          <Textarea
            placeholder="Description"
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            label="Description"
            labelPlacement="outside"
          />
          <div className="flex items-center justify-between ">
            <div className="flex items-center gap-1">
              <div>Public</div>
              <Tooltip content="Allowed only if Data Product is public">
                <Info height={16} />
              </Tooltip>
            </div>
            <Switch
              isDisabled={!dataProduct?.attributes?.Public}
              isSelected={isPublic}
              onValueChange={setIsPublic}
            />
          </div>
          <div className="flex flex-col gap-2">
            <div>Tags</div>
            <div className="bg-default-100 py-2 rounded-lg">
              <div className="flex flex-wrap gap-2 px-2">
                {tags.map((it, index) => (
                  <Chip
                    onClose={() =>
                      setTags((prev) => prev.filter((item) => it !== item))
                    }
                    variant="flat"
                    key={index}
                    size="sm"
                    color={colorPicker(it.length)}
                  >
                    {it}
                  </Chip>
                ))}
              </div>
              <Textarea
                classNames={{
                  inputWrapper: "data-[hover=true]:bg-default-100 shadow-none ",
                }}
                placeholder="Type then press enter"
                onKeyDown={handleKeyDown}
                value={tagInput}
                onChange={(e) => setTagInput(e.target.value)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="flex justify-end">
          <Button
            onClick={handleSubmit}
            size="md"
            color="primary"
            disabled={name.trim() === "" || desc.trim() === ""}
            className="w-20"
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const AnomalyDrawer = ({ isOpen, setIsOpen, threadResp }) => {
  const [selected, setSelected] = useState("contri");
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <Modal
      radius="none"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      className="absolute !m-0 top-0 right-0 h-screen z-100 overflow-y-auto"
      hideCloseButton={true}
      size="4xl"
    >
      <ModalContent className="px-4 py-4 overflow-y-auto">
        <ModalBody className="flex flex-col gap-8 overflow-y-auto">
          <AddModal
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            threadResp={threadResp}
          />
          <div className="flex items-center justify-between">
            <div>Anomaly</div>
            <Button
              color="primary"
              size="md"
              onClick={() => setIsModalOpen(true)}
            >
              Add Anomaly
            </Button>
          </div>
          <div className="h-[350px] border border-divider rounded-lg"></div>
          <div>
            <Code color="secondary">{threadResp?.anomaly}</Code>
          </div>
          <Tabs
            size="sm"
            selectedKey={selected}
            onSelectionChange={setSelected}
          >
            <Tab key={"contri"} title={"Top Contributers"}></Tab>
            <Tab key={"heat"} title={"Heat Map"}></Tab>
          </Tabs>
          <div className="flex-1 overflow-y-auto">
            {selected === "contri" ? <></> : <></>}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AnomalyDrawer;
