import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";

Font.register({
  family: "Helvetica",
  src: "https://fonts.gstatic.com/s/helveticaneue/v3/K9c7S8z5QfUCB2t3EKd1F8wIjggP6hE.woff", // Example URL for Helvetica
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 30,
    fontSize: 10,
  },
  header: {
    fontSize: 10,
    textAlign: "center",
    color: "grey",
    marginBottom: 20,
  },
  footer: {
    position: "absolute",
    fontSize: 10,
    textAlign: "center",
    color: "grey",
    bottom: 30,
    left: 0,
    right: 0,
  },
  title: {
    fontSize: 18, // Reduced to fit better in a PDF document
    textAlign: "center",
    fontFamily: "Oswald",
    marginBottom: 12, // Slightly reduced for better spacing
    color: "#1a73e8", // The blue color remains, as it's vibrant
  },
  subtitle: {
    fontSize: 14, // A bit smaller for a more professional look
    margin: "10px 0", // More subtle margins for compactness
    fontFamily: "Oswald",
    color: "#333333",
    borderBottom: "1px solid #cccccc", // Softer border color for a lighter feel
    paddingBottom: 4, // Increased padding for better separation
  },
  text: {
    marginBottom: 6, // More breathing room between paragraphs
    fontSize: 10, // Slightly larger for readability in PDF
    lineHeight: 1.2, // Improved line spacing for clarity
    textAlign: "justify", // Ensures a clean, professional look
    fontFamily: "Times-Roman", // Classic font for main text body in PDFs
    color: "#000000", // Standard black color for text readability
  },

  // title: {
  //   fontSize: 28,
  //   textAlign: "center",
  //   fontFamily: "Oswald",
  //   marginBottom: 20,
  //   color: "#1a73e8",
  // },
  // subtitle: {
  //   fontSize: 20,
  //   margin: "12px 0",
  //   fontFamily: "Oswald",
  //   color: "#333333",
  //   borderBottom: "1px solid #e5e5e5",
  //   paddingBottom: 4,
  // },
  // text: {
  //   marginBottom: 5,
  //   fontSize: 12,
  //   lineHeight: 1.2,
  //   textAlign: "justify",
  //   fontFamily: "Times-Roman",
  // },
  boldText: {
    fontFamily: "Oswald",
    fontWeight: "bold",
  },
  table: {
    display: "table",
    width: "100%",
    marginBottom: 20,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#e5e5e5",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "20%",
    backgroundColor: "#f0f0f0",
    borderStyle: "solid",
    borderColor: "#e5e5e5",
    borderWidth: 1,
    textAlign: "center",
    padding: 8,
    fontWeight: "bold",
    fontSize: 10,
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderColor: "#e5e5e5",
    borderWidth: 1,
    textAlign: "center",
    padding: 8,
  },
  tableCell: {
    fontSize: 10,
  },
  section: {
    marginBottom: 24,
  },
  pageBreak: {
    marginTop: 20,
    marginBottom: 20,
  },
  cover: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  coverTitle: {
    fontSize: 40,
    fontFamily: "Oswald",
    color: "#1a73e8",
    textAlign: "center",
    marginBottom: 20,
  },
  coverSubtitle: {
    fontSize: 20,
    fontFamily: "Oswald",
    color: "#555",
    textAlign: "center",
  },
  coverImage: {
    width: 200,
    height: 200,
    marginBottom: 40,
  },
});

export const Template1 = () => {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.cover}>
          {/* Add a logo or cover image here */}
          <Image
            style={styles.coverImage}
            src="https://via.placeholder.com/200"
          />
          <Text style={styles.coverTitle}>Audyt Corporation</Text>
          <Text style={styles.coverSubtitle}>Annual Report 2024</Text>
        </View>
      </Page>
      <Page style={styles.body} wrap>
        <Text style={styles.header} fixed>
          Audyt Corporation | Annual Report 2024
        </Text>

        <View wrap style={styles.body}>
          <Text style={styles.title}>Audyt Company Profile</Text>

          <View style={styles.section}>
            <Text style={styles.subtitle}>I. General Information</Text>
            <Text style={styles.text}>
              It should be noted that in the case of certain reported
              indicators, previous year(s) and current year values are not
              comparable on account of year on year change in the production,
              size and complexity of the Company. In FY2022-23, Tata Steel was
              also a more complex organisation compared to the previous year due
              to the acquisition and ramp up of Neelachal Ispat Nigam Limited
              and increased activity undertaken for the ongoing 5MnTPA expansion
              at Kalinganagar. The actual production was also different year on
              year, which has a direct impact on production driven indicators.
            </Text>
            <Text style={styles.text}>
              Audyt, one of the foremost steel manufacturers, is committed to
              sustainable practices while delivering high-quality products.
            </Text>
            <Text style={styles.text}>
              The corporation's headquarters is located in Bangalore, India.
            </Text>
            <Text style={styles.text}>
              It operates across several countries and maintains a presence in
              multiple global markets.
            </Text>
            <Text style={styles.text}>
              Audyt engages in activities related to steel production, mining,
              and the supply of various associated services.
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.subtitle}>II. Business Segments</Text>
            <Text style={styles.text}>13. Business Areas</Text>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Sl. No.</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Code</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Business Activity</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Revenue Code</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Text style={styles.tableCell}>Revenue Percentage</Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>1</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>241</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    Production of basic iron and steel
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>2410</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>100%</Text>
                </View>
              </View>
            </View>

            <Text style={styles.text}>15. Products/Services</Text>
            <Text style={styles.text}>
              Audyt operates within the following sectors:
            </Text>
            <Text style={styles.text}>
              • <Text style={styles.boldText}>Steel:</Text> Audyt is recognized
              as a prominent steel producer both in India and on the
              international stage, offering a diverse range of long and flat
              steel products.
            </Text>
            <Text style={styles.text}>
              • <Text style={styles.boldText}>Mining:</Text> Audyt is involved
              in the extraction of iron ore and coal.
            </Text>
            <Text style={styles.text}>
              • <Text style={styles.boldText}>Additional Products:</Text> Audyt
              manufactures a variety of value-added products and solutions
              catering to sectors like construction, infrastructure, automotive,
              and industry.
            </Text>
          </View>
        </View>

        <Text style={styles.footer} fixed>
          Audyt Corporation © 2024 | All rights reserved.
        </Text>
      </Page>

      {/* Second page */}
      <Page style={styles.body} wrap>
        <View style={styles.section}>
          <Text style={styles.subtitle}>
            III. Board of Directors Composition
          </Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCell}>Director Name</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCell}>Role</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCell}>Gender</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCell}>Other Directorships</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCell}>Committee Memberships</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCell}>Shares Held</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Sridhar Santhanam</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>CEO & Managing Director</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Male</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>6</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>2</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>0</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.subtitle}>IV. Stakeholder Disclosure</Text>
          <Text style={styles.text}>16. Stakeholder Engagement</Text>
          <Text style={styles.text}>
            Audyt actively communicates with a range of stakeholders, including
            employees, customers, suppliers, regulators, investors, and local
            communities, to ensure transparency and foster sustainable
            development.
          </Text>
        </View>

        <Text style={styles.footer} fixed>
          Audyt Corporation © 2024 | All rights reserved.
        </Text>
      </Page>
    </Document>
  );
};
