import React from "react";
import Charts from "./Charts";

const WasteInsight = () => {
  return <div className="my-8">
    {/* <Charts
     type="line" /> */}
  </div>;
};

export default WasteInsight;
