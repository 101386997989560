import React from "react";

const Loading = () => {
  // const theme=sessionStorage.getItem("Theme")
  return (
    <div className="flex items-center justify-center h-full backdrop-opacity-10 backdrop-invert">
      <lord-icon
        src="https://cdn.lordicon.com/jkppkiom.json"
        trigger="loop"
        colors={`primary:'#000000'`}
        state="loop-triangle"
        style={{ width: "40px", height: "40px" }}
      ></lord-icon>
    </div>
  );
};

export default Loading;
