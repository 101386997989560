import React from "react";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import ComingSoon from "../../components/ComingSoon";
import { Tab, Tabs } from "@nextui-org/react";
import EmissionInsight from "./EmissionInsight";
import WasteInsight from "./WasteInsight";
import WaterInsight from "./WaterInsight";

const Analytics = () => {
  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  console.log(
    `${process.env.REACT_APP_EVIDENCE_DEV_IP_ADDRESS}/orgnization/${OrgInfo}`,
    process.env.REACT_APP_EVIDENCE_DEV_IP_ADDRESS
  );
  return (
    <BreadCrumbsPage>
      <div>
        <div className="text-3xl font-medium">Sustainability Activities</div>
        <div className="mt-4">
          This dashboard provides enterprise-level insights into key sustainability metrics, including emissions, waste management, and water usage. By analyzing these data points, organizations can track their sustainability performance, identify areas for improvement, and ensure compliance with environmental regulations. These insights are critical for driving data-informed decisions and enhancing operational efficiency across all departments.
        </div>
        <div className="mt-8">
          <Tabs variant="light">
            <Tab key="emission-insight" title="Emissions Insight">
              <EmissionInsight />
            </Tab>
            {/* <Tab key="waste-insight" title="Waste Management Insight">
              <WasteInsight />
            </Tab> */}
            {/* <Tab key="water-insight" title="Water Usage Insight">
              <WaterInsight />
            </Tab> */}
          </Tabs>
        </div>
        {/* <ComingSoon /> */}
      </div>
    </BreadCrumbsPage>
  );
};

export default Analytics;
