import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});
export const Template2 = ({}) => {
  return (
    <Document>
      <Page style={styles.body}>
        <View wrap style={styles.body}>
          <Text style={styles.title}>Template 2</Text>
          <Text style={styles.text}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad in
            officia, incidunt iste pariatur deleniti similique blanditiis esse,
            at non quasi suscipit! Reiciendis mollitia sunt veniam quidem quia
            commodi libero illo sed blanditiis? Temporibus fugiat sapiente quia
            expedita, suscipit, ipsa nulla esse cumque unde possimus obcaecati
            rem dolorum asperiores voluptatem.
          </Text>
          <Text style={styles.text}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos
            totam sequi blanditiis sit dolorem ex placeat incidunt neque
            deserunt veniam?
          </Text>
          {/* All your text content here */}
        </View>
      </Page>
    </Document>
  );
};
