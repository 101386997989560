import React from "react";
import Charts from "./Charts";
import { Tabs, Tab } from "@nextui-org/react";
import CardGrid from "./CardGrid";

const EmissionInsight = () => {
  return (
    <div className="my-8">
      {/* <iframe
        src="http://localhost:3000/emissions-insight/1/"
        // src={`${process.env.REACT_APP_EVIDENCE_DEV_IP_ADDRESS}/orgnization/${OrgInfo}`}
        title="description"
        style={{
          width: "70vw",
          height: "70vh",
          border: "none",
        }}
        className="hide-scrollbar"
      /> */}
      {/* <Charts type="area" /> */}
      <div className="h-full">
        <Tabs variant="underlined">
          <Tab key="All emission" title="All emissions" className="h-full">
            <div className="h-full">
              <CardGrid />
            </div>
          </Tab>
          {/* <Tab key="Scope 1" title="Scope 1">
          <CardGrid/>
          </Tab>
          <Tab key="Scope 2" title="Scope 2">
          <CardGrid/>
          </Tab>
          <Tab key="Scope 3" title="Scope 3">
          <CardGrid/>
          </Tab> */}
        </Tabs>
      </div>
    </div>
  );
};

export default EmissionInsight;
