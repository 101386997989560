import {
  Button,
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Textarea,
  useDisclosure,
} from "@nextui-org/react";

import React, { useEffect, useState } from "react";

import {
  getDataWithFilterUrl,
  getSingleCollectionData,
  PublishData,
  UpdateSingleCollectionData,
} from "../../controllers/APIController";
import {
  FieldList,
  FrequecyCountMap,
  FrequencyDataObj,
} from "../../utils/constant";

import PreviewForm from "../FormBuilder/PreviewForm";
import AddModal from "../FormBuilder/AddModal";
import FieldInfoTable from "../FormBuilder/FieldInfoTable";
import { useNavigate, useParams } from "react-router-dom";

import {
  CustomFrequencyInput,
  DropDownInput,
} from "../FormBuilder/InputField/InputField";
import MetricTagInput from "../FormBuilder/InputField/MetricTagInput";
const initialFormData = {
  metricName: "",
  className: "",
  description: "",
};

const getJSonSchemaFromFieldInfoList = (fieldInfoList) => {
  const propertiesList = {};

  const requiredArr = [];
  fieldInfoList.forEach((data) => {
    const fieldInput = {};

    if (data["Required"]) {
      requiredArr.push(data["Title"]);
    }
    Object.keys(data).map((key) => {
      fieldInput[key.toLocaleLowerCase()] = data[key];
    });

    propertiesList[data["Title"]] = fieldInput;
  });

  const response = {
    type: "object",
    title: "",
    properties: propertiesList,
    description: "",
  };
  return response;
};

const getLinkJsonReponse = (list) => {
  const reponse = [];
  list.forEach((item) => {
    reponse.push({
      id: item?.id,
      name: item.Title,
      link: item.link,
    });
  });
  return {
    Links: reponse,
  };
};

const UIMetricBuilderValidation = (
  formData,
  fieldInfoList,
  requiredArr,
  allMetricName
) => {
  const err = {};
  let isValid = true;

  if (fieldInfoList.length === 0) {
    err["BuildData"] = "Build Data should have atleast one field";
    isValid = false;
  }
  if (allMetricName?.includes(formData["metricName"]?.trim()?.toLowerCase())) {
    err["metricName"] = "Metric Name already have been used";
    isValid = false;
  }
  Object.keys(formData).map((key) => {
    if (requiredArr.includes(key) && formData[key] === "") {
      err[key] = `${key} is required`;
      isValid = false;
    }
  });
  return { isValid: isValid, error: err };
};

const UISurveyBuilder = () => {
  const navigator = useNavigate();
  const { _orgId } = useParams();

  const [allMetricName, setAllMetricName] = useState([]);

  const [formData, setFormData] = useState(initialFormData);

  const [frequency, setFrequency] = useState(FrequencyDataObj?.values[0]?.name);
  const [customFrequency, setCustomFrequency] = useState(0.01);

  const showCustomFrequencyInput = frequency === "Custom";

  const [validationErrors, setValidationErrors] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const [fieldType, setFieldType] = useState(""); // field type that we want to create
  const [fieldInfoList, setFieldInfoList] = useState([]); // JsonSchema Input field info
  const [LinkInfoList, setLinkInfoList] = useState([]); // Link field info in Metric
  const [selectedFormulaList, setSelectedFormulaList] = useState([]);

  const [frequencyCounter, setFrequencyCounter] = useState([]);

  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);

  const [tagsValidation, setTagsValidation] = useState("");

  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const {
    isOpen: isOpenLink,
    onOpen: onOpenLink,
    onOpenChange: onOpenChangeLink,
  } = useDisclosure();

  const requiredArr = [
    "metricName",
    "Category",
    "jsonSchema",
    "ApprovalWorkFlow",
  ];

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged?.user?.id;
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const fieldListValues = Object.keys(FieldList).map((key) => {
    return [key, FieldList[key]];
  });
  const changeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const showPreviewHandler = () => {
    setShowPreview(false);
    const validationResponse = UIMetricBuilderValidation(
      formData,
      fieldInfoList,
      requiredArr,
      allMetricName
    );

    setValidationErrors(validationResponse.error);
    if (!validationResponse.isValid) {
      return;
    }

    const JsonSchemaData = getJSonSchemaFromFieldInfoList(fieldInfoList);

    const publishData = {
      Name: formData.metricName,
      ClassName: "Survey",
      Description: formData.description,
      JsonSchema: JsonSchemaData,
      Links: getLinkJsonReponse(LinkInfoList),
      Frequency: frequency,
      TagList: tags,
    };
    setPreviewData(publishData);
    setShowPreview(true);
  };

  const submitHandler = async () => {
    const validationResponse = UIMetricBuilderValidation(
      formData,
      fieldInfoList,
      requiredArr,
      allMetricName
    );

    if (!validationResponse.isValid) {
      setValidationErrors(validationResponse.error);
      return;
    }
    const JsonSchemaData = getJSonSchemaFromFieldInfoList(
      fieldInfoList,
      selectedFormulaList
    );

    const publishData = {
      Name: formData.metricName,
      ClassName: formData.className,
      Category: "Survey",
      Description: formData.description,
      JsonSchema: JsonSchemaData,
      Links: getLinkJsonReponse(LinkInfoList),
      FrequencyValue:
        frequency === "Custom" ? +customFrequency : FrequecyCountMap[frequency],
      TagList: tags,
      Organization: OrgInfo,
    };

    let result = await PublishData({ data: publishData }, "metrics");

    const newTags = tags?.filter((name) => !allTags?.includes(name));
    const updatedTags = [...allTags, ...newTags];
    await UpdateSingleCollectionData("tag", { List: updatedTags });

    navigator(`/${_orgId}/data-Questionaire`);
  };

  const addFieldHandler = (listItem) => {
    setFieldInfoList([...fieldInfoList, listItem]);
  };

  const addLinkHandler = (listItem) => {
    setLinkInfoList([...LinkInfoList, listItem]);
  };

  useEffect(() => {
    getDataWithFilterUrl("metrics")
      .then((data) => {
        const list = data?.data?.map((a) => {
          return a?.attributes?.Name.trim().toLowerCase();
        });
        setAllMetricName(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getSingleCollectionData("frequency-counter")
      .then((data) => {
        setFrequencyCounter(
          data?.data?.attributes?.FrequencyCounter?.list || []
        );
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    getSingleCollectionData("tag")
      .then((data) => {
        setAllTags(data?.data?.attributes?.List);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="flex items-start justify-between my-2">
      <div className="flex flex-col items-center w-[50%]">
        {/* Metric Name Input  */}
        <Input
          type="text"
          className="my-8"
          variant="faded"
          isRequired
          label="Metric Name"
          labelPlacement="outside"
          placeholder=" "
          name="metricName"
          onChange={changeHandler}
          isInvalid={validationErrors["metricName"]}
          errorMessage={
            validationErrors["metricName"] && validationErrors["metricName"]
          }
        />

        {/* ClassName Input */}
        <Input
          type="text"
          className="my-4"
          variant="faded"
          label="Class Name"
          placeholder=" "
          labelPlacement="outside"
          name="className"
          onChange={changeHandler}
        />

        {/* Description Input  */}
        <Textarea
          label="Description"
          labelPlacement="outside"
          variant="faded"
          className="my-4"
          name="description"
          onChange={changeHandler}
        />

        {/* Frequency DropDown */}

        <DropDownInput
          data={FrequencyDataObj}
          value={frequency}
          validationErrors={1}
          onChangeHandler={(title, value, type) => setFrequency(value)}
        />
        {showCustomFrequencyInput && (
          <CustomFrequencyInput
            value={customFrequency}
            onChangeHandler={(value) => setCustomFrequency(value)}
          />
        )}

        {/* Metrics Tags Input */}
        <MetricTagInput
          tags={tags}
          setTags={setTags}
          IntialvalidationError={tagsValidation}
          allTags={allTags}
        />

        {/* JsonSchema Input */}

        <div className="w-full min-h-96">
          <div className="flex items-center gap-4">
            <p className="text-lg font-medium">Build your Metric Schema</p>
            <Chip size="sm" radius="sm" color="danger" variant="flat">
              Beta
            </Chip>
          </div>
          {validationErrors["BuildData"] && (
            <p className="text-[#f76497]">{validationErrors["BuildData"]}</p>
          )}
          <p className="my-4 text-sm">
            Choose a data type and begin building your metric forms.
          </p>

          <div className="flex justify-end my-4 mr-4">
            <Dropdown>
              <DropdownTrigger>
                <Button color="secondary" size="sm">
                  Add a new field
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Static Actions"
                selectionMode="single"
                onSelectionChange={(event) => {
                  setFieldType(
                    Array.from(event).join(", ").replaceAll("_", " ")
                  );
                  onOpen();
                }}
              >
                {fieldListValues.map(
                  (row) =>
                    row[1].name !== "linkUrl" && (
                      <DropdownItem key={row[1].name} name={row[1].name}>
                        {row[1].title}
                      </DropdownItem>
                    )
                )}
              </DropdownMenu>
            </Dropdown>
          </div>

          {isOpen && (
            <AddModal
              onSubmitHandler={addFieldHandler}
              // id={deleteMetricId}
              modalHeading={"Are you sure you want to delete this Entry?"}
              isOpen={isOpen}
              onOpen={onOpen}
              onOpenChange={onOpenChange}
              fieldType={fieldType}
              id={Date.now()}
            />
          )}

          <FieldInfoTable
            fieldInfoList={fieldInfoList}
            setFieldInfoList={setFieldInfoList}
          />
        </div>

        {/* Links Input */}

        <div className="w-full min-h-96">
          <div className="flex items-center gap-4">
            <p className="text-lg font-medium">Attach your reference links</p>
            <Chip size="sm" radius="sm" color="danger" variant="flat">
              Beta
            </Chip>
          </div>
          {validationErrors["BuildData"] && (
            <p className="text-[#f76497]">{validationErrors["BuildData"]}</p>
          )}
          <p className="my-4 text-sm">
            Attach reference links for users to fill your metrics easier
          </p>

          <div className="flex justify-end my-4 mr-4">
            <Button color="secondary" size="sm" onClick={() => onOpenLink()}>
              Add a new link
            </Button>
          </div>

          {isOpenLink && (
            <AddModal
              onSubmitHandler={addLinkHandler}
              // id={deleteMetricId}
              modalHeading={"Are you sure you want to delete this Entry?"}
              isOpen={isOpenLink}
              onOpen={onOpenLink}
              onOpenChange={onOpenChangeLink}
              fieldType="link"
              id={Date.now()}
            />
          )}

          <FieldInfoTable
            fieldInfoList={LinkInfoList}
            setFieldInfoList={setLinkInfoList}
            headersList={["Name", "Link", ""]}
          />
        </div>

        {/* Preview,Submit button */}
        <div className="flex gap-4 my-4 w-full">
          <Button
            color="danger"
            variant="light"
            size="sm"
            onClick={showPreviewHandler}
          >
            Preview
          </Button>
          <Button color="danger" size="sm" onClick={submitHandler}>
            Submit
          </Button>
        </div>
      </div>

      {/* Preview Card Open */}
      {showPreview && (
        <div className="flex flex-col items-center w-[48%]">
          <PreviewForm data={previewData} />
        </div>
      )}
    </div>
  );
};

export default UISurveyBuilder;
