import {
  Card,
  CardHeader,
  Divider,
  Image,
  CardBody,
  CardFooter,
  Link,
} from "@nextui-org/react";
import React from "react";

const RenderDocs = ({ filteredDocuments }) => {
  // Function to get the avatar URL from the data array
  const getAvatar = "https://via.placeholder.com/40";

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-2 pt-12 px-4">
        {filteredDocuments.map((document, index) => (
          <Card
            key={index}
            className={`max-w-[400px] border border-divider shadow-none transition-opacity duration-800 ease-in-out opacity-1 animate-fadeIn`}
          >
            <CardHeader className="flex gap-3">
              <Image
                alt="Document Avatar"
                width={40}
                height={40}
                src={getAvatar} // Get the avatar based on document value
                radius="sm"
              />
              <div className="flex flex-col">
                <p className="text-md">{document?.document_title}</p>
                <p className="text-small text-default-500">
                  {document?.document_url}
                </p>
              </div>
            </CardHeader>
            <Divider />
            <CardBody className="max-h-[100px] overflow-hidden">
              <p>
                {document.document_description
                  ? document.document_description.substring(0, 80) +
                    (document.document_description.length > 80 ? "..." : "")
                  : ""}
              </p>
            </CardBody>
            <Divider />
            <CardFooter>
              <Link isExternal showAnchorIcon href={document?.document_url}>
                Visit source
              </Link>
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default RenderDocs;
