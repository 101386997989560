import {
  Button,
  Card,
  CardBody,
  Input,
  Textarea,
  Tooltip,
} from "@nextui-org/react";
import { Controlled as CodeMirror } from "react-codemirror2";

import React, { useEffect, useState } from "react";

import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/javascript-hint";

import { isJsonString } from "../../utils/helper";
import {
  getSingleCollectionData,
  PublishData,
  UpdateSingleCollectionData,
} from "../../controllers/APIController";
import {
  FrequecyCountMap,
  FrequencyDataObj,
  JSonResponseInfoContent,
} from "../../utils/constant";
import PreviewForm from "../FormBuilder/PreviewForm";
import { getDataWithFilterUrl } from "../../controllers/APIController";
import { useNavigate, useParams } from "react-router-dom";
import { DropDownInput } from "../FormBuilder/InputField/InputField";
import MetricTagInput from "../FormBuilder/InputField/MetricTagInput";
// import 'codemirror/mode/json/json.js';

const initialFormData = {
  metricName: "",
  categoryName: "",
  className: "",
  description: "",
};

const MetricBuilderValidation = (
  formData,
  jsonData,
  linkData,
  requiredArr,
  allMetricName
) => {
  const err = {};
  let isValid = true;

  if (!isJsonString(jsonData)) {
    err["JsonSchema"] = "Invalid JsonSchema";
    isValid = false;
  }
  if (!isJsonString(linkData)) {
    err["linkData"] = "Invalid Link Data";
    isValid = false;
  }
  if (allMetricName?.includes(formData["metricName"]?.trim()?.toLowerCase())) {
    err["metricName"] = "Metric Name already have been used";
    isValid = false;
  }

  Object.keys(formData).map((key) => {
    if (requiredArr.includes(key) && formData[key] === "") {
      err[key] = `${key} is required`;
      isValid = false;
    }
  });
  return { isValid: isValid, error: err };
};

const JSonResponseInfoContentComp = () => {
  return (
    <pre className="text-xs font-semibold ">
      {JSON.stringify(JSonResponseInfoContent, null, 2)}
    </pre>
  );
};

const JsonSurveyBuilder = () => {
  const navigator = useNavigate();
  const { _orgId } = useParams();
  const requiredArr = ["metricName", "jsonSchema"];

  const [formData, setFormData] = useState(initialFormData);
  const [jsonData, setJsonData] = useState("");
  const [linkData, setLinkData] = useState("");
  const [allMetricName, setAllMetricName] = useState([]);

  const [frequency, setFrequency] = useState(FrequencyDataObj?.values[0]?.name);
  const [customFrequency, setCustomFrequency] = useState(0.01);

  const showCustomFrequencyInput = frequency === "Custom";

  const [validationErrors, setValidationErrors] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const [frequencyCounter, setFrequencyCounter] = useState([]);

  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);

  const [tagsValidation, setTagsValidation] = useState("");

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const changeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleJsonChange = (editor, data, value) => {
    setJsonData(value);
  };
  const handleLinkChange = (editor, data, value) => {
    setLinkData(value);
  };
  const showPreviewHandler = () => {
    setShowPreview(false);

    const validationResponse = MetricBuilderValidation(
      formData,
      jsonData,
      linkData,
      requiredArr
    );

    setValidationErrors(validationResponse.error);
    if (!validationResponse.isValid) {
      return;
    }

    const frameWorkResponse =
      formData?.frameWork !== ""
        ? { id: formData?.frameWork?.split(",") }
        : { id: [] };
    const publishData = {
      Name: formData.metricName,
      Category: formData.categoryName,
      ClassName: formData.className,
      Description: formData.description,
      Framework: frameWorkResponse,
      JsonSchema: JSON.parse(jsonData),
      Links: JSON.parse(linkData),
      Frequency: frequency,
    };

    setPreviewData(publishData);
    setShowPreview(true);
  };
  const submitHandler = async () => {
    const validationResponse = MetricBuilderValidation(
      formData,
      jsonData,
      linkData,
      requiredArr,
      allMetricName
    );

    if (!validationResponse.isValid) {
      setValidationErrors(validationResponse.error);
      return;
    }

    const publishData = {
      Name: formData.metricName,
      Category: "Survey",
      ClassName: formData.className,
      Description: formData.description,
      JsonSchema: JSON.parse(jsonData),
      Links: JSON.parse(linkData),
      Frequency: frequency,
      TagList: tags,
    };

    let result = await PublishData({ data: publishData }, "metrics");

    const frequencyObj = {
      metric_id: result?.data?.id,
      createdAt: Date.now(),
      frequency:
        frequency === "Custom" ? customFrequency : FrequecyCountMap[frequency],
      counter: 0,
    };

    const newFrequencyCounter = [...frequencyCounter, frequencyObj];
    const frequecyResponse = await UpdateSingleCollectionData(
      "frequency-counter",
      { FrequencyCounter: { list: newFrequencyCounter } }
    );

    const newTags = tags?.filter((name) => !allTags?.includes(name));
    const updatedTags = [...allTags, ...newTags];
    await UpdateSingleCollectionData("tag", { List: updatedTags });

    navigator(`/${_orgId}/data-Questionaire`);
  };

  function jsonHint(editor) {
    const jsonWords = ["{}", "name", "version", "author"]; // Add your JSON keywords here
    const cursor = editor.getCursor();
    const token = editor.getTokenAt(cursor);

    // Filter JSON words that match the current token
    const matches = jsonWords.filter((word) => word.startsWith(token.string));

    // Return hint object with filtered matches
    return {
      list: matches,
      from: { line: cursor.line, ch: token.start },
      to: { line: cursor.line, ch: token.end },
    };
  }

  // Handler for inputRead event to trigger autocomplete
  const handleInputRead = (editor, data, value) => {
    const cursor = editor.getCursor();
    const line = editor.getLine(cursor.line);
    const lastChar = line.charAt(cursor.ch - 1);

    // Check if the last character typed is a letter or a digit
    if (/[a-zA-Z0-9]/.test(lastChar)) {
      editor.execCommand("autocomplete");
    }
  };

  useEffect(() => {
    getDataWithFilterUrl("metrics")
      .then((data) => {
        const list = data?.data?.map((a) => {
          return a?.attributes?.Name.trim().toLowerCase();
        });
        setAllMetricName(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getSingleCollectionData("tag")
      .then((data) => {
        setAllTags(data?.data?.attributes?.List);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="flex items-start justify-between my-2">
      <div className="flex flex-col items-center w-[50%]">
        {/* Metric Name Input  */}
        <Input
          type="text"
          className="my-4"
          variant="faded"
          isRequired
          label="Metric Name"
          labelPlacement="outside"
          placeholder=" "
          name="metricName"
          onChange={changeHandler}
          isInvalid={validationErrors["metricName"]}
          errorMessage={
            validationErrors["metricName"] && validationErrors["metricName"]
          }
        />

        {/* ClassName Input */}
        <Input
          type="text"
          className="my-4"
          variant="faded"
          label="Class Name"
          placeholder=" "
          labelPlacement="outside"
          name="className"
          onChange={changeHandler}
        />

        {/* Description Input  */}
        <Textarea
          label="Description"
          labelPlacement="outside"
          variant="faded"
          className="my-4"
          name="description"
          onChange={changeHandler}
        />

        {/* Frequency DropDown */}

        <DropDownInput
          data={FrequencyDataObj}
          value={frequency}
          validationErrors={1}
          onChangeHandler={(title, value, type) => setFrequency(value)}
        />

        {/* Metrics Tags Input */}
        <MetricTagInput
          tags={tags}
          setTags={setTags}
          IntialvalidationError={tagsValidation}
          allTags={allTags}
        />

        {/* JsonSchema Input */}

        <div className="w-full my-2 mb-12">
          <p className="flex items-center gap-1 text-gray text-sm mb-2">
            <span>Json Schema</span>
            <Tooltip
              content={<JSonResponseInfoContentComp />}
              className="flex items-center cursor-pointer"
            >
              <lord-icon
                src="https://cdn.lordicon.com/yxczfiyc.json"
                style={{ width: "18px", height: "18px" }}
                trigger="hover"
                colors="primary:#8492a6"
              ></lord-icon>
            </Tooltip>
            <p className="text-[#f76497] text-">
              {validationErrors["JsonSchema"] && validationErrors["JsonSchema"]}
            </p>
          </p>

          <Card>
            <CardBody className="p-0">
              <CodeMirror
                value={jsonData}
                options={{
                  mode: "javascript",
                  theme: "material",
                  lineNumbers: true,
                  tabSize: 2,
                  extraKeys: { "Ctrl-Space": "autocomplete" },
                }}
                onChange={handleInputRead}
                onBeforeChange={handleJsonChange}
                editorDidMount={(editor) => {
                  editor.on("inputRead", handleInputRead);
                  editor.setOption("hintOptions", {
                    hint: jsonHint,
                  });
                }}

                //   onKeyDown={handleKeyDown} // Handle key down event
              />
            </CardBody>
          </Card>
        </div>

        {/* Links Input */}

        <div className="w-full my-2 mb-12">
          <p className="flex items-center gap-1 text-gray text-sm mb-2">
            <span>Link Schema</span>
            <Tooltip
              content={<JSonResponseInfoContentComp />}
              className="flex items-center cursor-pointer"
            >
              <lord-icon
                src="https://cdn.lordicon.com/yxczfiyc.json"
                style={{ width: "18px", height: "18px" }}
                trigger="hover"
                colors="primary:#8492a6"
              ></lord-icon>
            </Tooltip>
            <p className="text-[#f76497] text-">
              {validationErrors["linkData"] && validationErrors["linkData"]}
            </p>
          </p>

          <Card>
            <CardBody className="p-0">
              <CodeMirror
                value={linkData}
                options={{
                  mode: "javascript",
                  theme: "material",
                  lineNumbers: true,
                  tabSize: 2,
                  extraKeys: { "Ctrl-Space": "autocomplete" },
                }}
                onChange={handleInputRead}
                onBeforeChange={handleLinkChange}
                editorDidMount={(editor) => {
                  editor.on("inputRead", handleInputRead);
                  editor.setOption("hintOptions", {
                    hint: jsonHint,
                  });
                }}

                //   onKeyDown={handleKeyDown} // Handle key down event
              />
            </CardBody>
          </Card>
        </div>

        {/* Preview,Submit button */}
        <div className="flex gap-4 my-4 w-full">
          <Button
            color="danger"
            variant="light"
            size="sm"
            onClick={showPreviewHandler}
          >
            Preview
          </Button>
          <Button color="danger" size="sm" onClick={submitHandler}>
            Submit
          </Button>
        </div>
      </div>

      {/* Preview Card Open */}
      {showPreview && (
        <div className="flex flex-col items-center w-[48%]">
          <PreviewForm data={previewData} />
        </div>
      )}
    </div>
  );
};

export default JsonSurveyBuilder;
