import {
  Button,
  Input,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import SearchIcon from "../../../Images/Search";
import {
  getDataWithFilterUrl,
  StringLimit,
} from "../../../controllers/APIController";

import AddEvidenceModal from "../../Evidences/AddEvidenceModal";
import NullData from "../../../components/ErrorPage/NullData";
const FileEvidenceInput = ({
  data,
  value,
  validationErrors,
  onChangeHandler,
}) => {
  const [reRenderPage,setReRenderPage] = useState(true);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  
  const [searchText, setSearchText] = useState("");
  const [evidenceData, setEvidenceData] = useState([]);
  const [filterEvidenceData, setFilterEvidenceData] = useState([]);
  const [page, setPage] = useState(1);

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const searchHandler = (event) => {
    const text = event.target.value;
    const list = evidenceData.filter((item) => {
      return item?.attributes?.Name?.toLowerCase()?.includes(
        text?.toLowerCase()
      );
    });
    setSearchText(text);
    setFilterEvidenceData(list);
  };
  const navigateHandler = (event, url) => {
    event.stopPropagation();
    event.preventDefault();

    console.log("hello");
    const anchor = document.createElement("a");
    anchor.href = `${process.env.REACT_APP_STRAPI_URL}/${url}`;
    anchor.download = url; // Set the desired filename
    anchor.click();
  };

  useEffect(() => {
    getDataWithFilterUrl("evidences", orgFilterUrl)
      .then(async (data) => {
        console.log(data);
        
        const dataList = data?.data || [];
        setEvidenceData(dataList);
        setFilterEvidenceData(dataList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const rowsPerPage = 15;
  const pages = Math.ceil(filterEvidenceData?.length / rowsPerPage);

  console.log(filterEvidenceData, orgFilterUrl, value);

  return (
    <>
      <AddEvidenceModal
        onSubmitHandler={1}
        id={1}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        setReRenderPage={setReRenderPage}
      />
      <div className="mb-16">
        <div>
          {data?.title || "Add Evidences"}
          {data?.required && <span style={{ color: "#f31b66" }}> *</span>}
        </div>
        <div className="flex justify-end items-center mt-2">
          <Button
            color="danger"
            size="sm"
            startContent={
              <lord-icon
                src="https://cdn.lordicon.com/smwmetfi.json"
                trigger="hover"
                colors="primary:#ffffff"
                style={{ width: "20px", height: "20px" }}
              ></lord-icon>
            }
            onClick={() => onOpen()}
          >
            Upload file
          </Button>
        </div>
        <div className="w-full">
          <Input
            classNames=""
            placeholder="Search Evidences"
            className="mt-4 w-full"
            startContent={
              <SearchIcon
                className={`text-large text- pointer-events-none flex-shrink-0 `}
              />
            }
            value={searchText}
            onChange={searchHandler}
          />

          <div className="mt-4">
            <Table
              aria-label="Controlled table metrics"
              removeWrapper
              selectionMode="multiple"
              isHeaderSticky
              selectedKeys={new Set(value)}
              onSelectionChange={(event) => {
                onChangeHandler(data?.title, Array.from(event), data?.type);
              }}
              classNames={{
                base: "max-h-[300px] overflow-scroll hide-scrollbar",
              }}
            >
              <TableHeader>
                <TableColumn>Name</TableColumn>
                <TableColumn>Description</TableColumn>
                <TableColumn>View Resource</TableColumn>
              </TableHeader>
              <TableBody
                className="max-h-16 overflow-hidden"
                emptyContent={
                  <NullData title="Evidence not Found. Click on Upload file to make a new one." />
                }
              >
                {filterEvidenceData?.map((item, index) => {
                  const lowerPageLimit = (page - 1) * rowsPerPage;
                  const upperPageLimit = page * rowsPerPage;
                  if (index < lowerPageLimit || index >= upperPageLimit)
                    return null;
                  return (
                    <TableRow key={item.id}>
                      <TableCell>{item?.attributes?.Name}</TableCell>
                      <TableCell>
                        {StringLimit(item?.attributes?.Description || "", 15)}
                      </TableCell>

                      <TableCell>
                        <div
                          className="text-blue-600 font-semibold cursor-pointer flex w-full"
                          onClick={(event)=>navigateHandler(event,item?.attributes?.EvidenceUrl)}
                        >
                          View Source
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileEvidenceInput;
