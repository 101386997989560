// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Image,
//   Input,
//   Progress,
//   Select,
//   SelectItem,
// } from "@nextui-org/react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import NoApproval from "../../Images/NoApproval.svg";
// import Approval1 from "../../Images/OneStep.svg";
// import Approval2 from "../../Images/two-step-image.svg";
// import { faArrowRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
// import { Link } from "react-router-dom";
// import {
//   FilterUser,
//   getAlldata,
//   UpdateSingleCollectionData,
// } from "../../controllers/APIController";

// export default function Onboarding() {
//   const [currentPage, setCurrentPage] = useState(1);
//   const [selectedFlow, setSelectedFlow] = useState(1);
//   const [ValidationConditions, setValidationConditions] = useState(false);
//   const [emails, setEmails] = useState([]);
//   const [inputValue, setInputValue] = useState("");
//   const [VerificationError, setVerificationError] = useState("");
//   const [ListOfUsers, setListOfUsers] = useState(null);
//   const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   useEffect(() => {
//     FilterUser(inputValue)
//       .then((data) => {
//         setListOfUsers(data);
//       })
//       .catch((err) => {
//         console.log("error", err);
//       });
//   }, [inputValue]);

//   const Validation = () => {
//     let IsValid = true;

//     if (!inputValue) {
//       setVerificationError("Email is required");
//       IsValid = false;
//     } else if (!EmailRegex.test(inputValue)) {
//       setVerificationError("Enter a Valid email format");
//       IsValid = false;
//     } else if (ListOfUsers) {
//       IsValid = false;
//       setVerificationError("User already exists.");
//     } else if (emails && emails.includes(inputValue)) {
//       IsValid = false;
//       setVerificationError("Email already exist in invite list.");
//     } else {
//       setVerificationError(null);
//     }
//     return IsValid;
//   };

//   const handleKeyPress = (event) => {
//     if (
//       (event.key === " " && inputValue.trim() !== "") ||
//       (event.key === "Enter" && inputValue.trim() !== "")
//     ) {
//       const Validate = Validation();
//       if (Validate) {
//         setValidationConditions(false);
//         event.preventDefault();
//         setEmails([...emails, inputValue.trim()]);
//         setInputValue("");
//       } else {
//         setValidationConditions(true);
//       }
//     }
//   };
//   const [formData, setFormData] = useState({
//     Name: "",
//     Address: "",
//     City: "",
//     Postal: "",
//   });
//   const [FormDataError, setFormDataError] = useState({
//     NameError: "",
//     AddressError: "",
//     CityError: "",
//     PostalError: "",
//   });

//   const [formData_02, setFormData_02] = useState({
//     Reporting_Year: "",
//     Date: "",
//     Reporting_Template: "",
//   });

//   const [Validations, setValidation] = useState({
//     Reporting_YearError: "",
//     DateError: "",
//     Reporting_TemplateError: "",
//   });
//   const [ValidateCondition, setValidateCondition] = useState(false);

//   useEffect(() => {
//     const parsedData = JSON.parse(sessionStorage.getItem("Step_02"));
//     if (parsedData) {
//       setFormData_02(parsedData);
//     }
//   }, []);

//   const Validation2 = () => {
//     let isValid = true;

//     if (!formData_02.Reporting_Year) {
//       isValid = false;
//       setValidation((prevValidations) => ({
//         ...prevValidations,
//         Reporting_YearError: "Reporting year is required.",
//       }));
//     } else {
//       setValidation((prevValidations) => ({
//         ...prevValidations,
//         Reporting_YearError: null,
//       }));
//     }

//     if (!formData_02.Date) {
//       isValid = false;
//       setValidation((prevValidations) => ({
//         ...prevValidations,
//         DateError: "Date is required.",
//       }));
//     } else {
//       setValidation((prevValidations) => ({
//         ...prevValidations,
//         DateError: null,
//       }));
//     }

//     if (!formData_02.Reporting_Template) {
//       isValid = false;
//       setValidation((prevValidations) => ({
//         ...prevValidations,
//         Reporting_TemplateError: "Reporting Template is required.",
//       }));
//     } else {
//       setValidation((prevValidations) => ({
//         ...prevValidations,
//         Reporting_TemplateError: null,
//       }));
//     }

//     return isValid;
//   };

//   useEffect(() => {
//     Validation2();
//   }, []);

//   const handleButtonclick = () => {
//     const Validate = Validation2();
//     if (Validate) {
//       setValidateCondition(false);
//       sessionStorage.setItem("Step_02", JSON.stringify(formData_02));
//       sessionStorage.setItem(
//         "Reporting_Template",
//         JSON.stringify(formData_02.Reporting_Template)
//       );
//     } else {
//       setValidateCondition(true);
//     }
//   };

//   const Reporting_Template = JSON.parse(
//     sessionStorage.getItem("Reporting_Template")
//   );
//   const CollectionName = "tenant";

//   useEffect(() => {
//     getAlldata(CollectionName)
//       .then((data) => {
//         if (data.data !== null) {
//           setFormData(data.data.attributes);
//         }
//       })
//       .catch((err) => console.log("error", err));
//   }, [CollectionName]);

//   const Validation1 = () => {
//     let isValid = true;

//     if (!formData.Name) {
//       isValid = false;
//       setFormDataError((prevValidations) => ({
//         ...prevValidations,
//         NameError: "Organization name required.",
//       }));
//     } else {
//       setFormDataError((prevValidations) => ({
//         ...prevValidations,
//         NameError: null,
//       }));
//     }

//     if (!formData.Address) {
//       isValid = false;
//       setFormDataError((prevValidations) => ({
//         ...prevValidations,
//         AddressError: "Address is required.",
//       }));
//     } else {
//       setFormDataError((prevValidations) => ({
//         ...prevValidations,
//         AddressError: null,
//       }));
//     }

//     if (!formData.City) {
//       isValid = false;
//       setFormDataError((prevValidations) => ({
//         ...prevValidations,
//         CityError: "City is required.",
//       }));
//     } else {
//       setFormDataError((prevValidations) => ({
//         ...prevValidations,
//         CityError: null,
//       }));
//     }

//     if (!formData.Postal) {
//       isValid = false;
//       setFormDataError((prevValidations) => ({
//         ...prevValidations,
//         PostalError: "Postal code is required.",
//       }));
//     } else {
//       setFormDataError((prevValidations) => ({
//         ...prevValidations,
//         PostalError: null,
//       }));
//     }

//     return isValid;
//   };

//   const SaveChangesHandler = async () => {
//     const isValid = Validation1();

//     if (isValid) {
//       setValidationConditions(false);
//       const response = await UpdateSingleCollectionData("tenant", formData);
//       console.log("response", response);
//       // Handle success or redirect as needed
//     } else {
//       setValidationConditions(true);
//     }
//   };
//   const [images, setImages] = useState({
//     flow1: NoApproval,
//     flow2: Approval1,
//     flow3: Approval2,
//   });

//   const handleFlowSelect = (event) => {
//     setSelectedFlow(event.target.value);
//   };
//   const dropdown1 = [
//     { label: "Annual" },
//     { label: "Semi-Annual" },
//     { label: "Quarter" },
//     { label: "Month" },
//     { label: "4 week period" },
//   ];
//   const dropdown2 = [
//     { id: 1, label: "No Approval Flow" },
//     { id: 2, label: "One Step Approval" },
//     { id: 3, label: "Two Step Approval" },
//   ];

//   const nextPage = () => {
//     setCurrentPage(currentPage + 1);
//   };

//   const prevPage = () => {
//     setCurrentPage(currentPage - 1);
//   };

//   const renderForm = () => {
//     switch (currentPage) {
//       case 1:
//         return (
//           <>
//             <div className="p-20 max-w-xl m-auto">
//               <div className="flex justify-center p-10">
//                 <Image
//                   width={100}
//                   alt="NextUI hero Image"
//                   src="/Assets/logo.svg"
//                 />
//               </div>
//               <Progress value={25} className="w-96 m-auto" color="danger" />
//               <p className="mt-8">Name of your Organization</p>
//               <Input
//                 type="text"
//                 placeholder="Enter your organization name"
//                 className="mt-6"
//                 invalid={ValidationConditions && !formData.Name}
//                 invalidText={FormDataError.NameError}
//                 value={formData.Name}
//                 onChange={(e) =>
//                   setFormData({ ...formData, Name: e.target.value })
//                 }
//               />
//               <p className="mt-4">Address</p>
//               <Input
//                 type="text"
//                 placeholder="Enter your address"
//                 className="mt-4"
//                 invalid={ValidationConditions && !formData.Address}
//                 invalidText={FormDataError.AddressError}
//                 value={formData.Address}
//                 onChange={(e) =>
//                   setFormData({ ...formData, Address: e.target.value })
//                 }
//               />
//               <p className="mt-4">City</p>
//               <Input
//                 type="text"
//                 placeholder="Enter your city"
//                 className="mt-4"
//                 invalid={ValidationConditions && !formData.City}
//                 invalidText={FormDataError.CityError}
//                 value={formData.City}
//                 onChange={(e) =>
//                   setFormData({ ...formData, City: e.target.value })
//                 }
//               />
//               <p className="mt-4">Postal code</p>
//               <Input
//                 type="text"
//                 placeholder="Enter your postal code"
//                 className="mt-4"
//                 value={formData.Postal}
//                 invalid={ValidationConditions && !formData.Postal}
//                 invalidText={FormDataError.PostalError}
//                 onChange={(e) =>
//                   setFormData({ ...formData, Postal: e.target.value })
//                 }
//               />
//               <div className="flex justify-end">
//                 <Button className="mt-10" color="danger" onClick={nextPage}>
//                   Next
//                 </Button>
//               </div>
//             </div>
//           </>
//         );
//       case 2:
//         return (
//           <>
//             <div className="p-20 max-w-xl m-auto">
//               <div className="flex justify-center p-10">
//                 <Image
//                   width={100}
//                   alt="NextUI hero Image"
//                   src="/Assets/logo.svg"
//                 />
//               </div>
//               <Progress
//                 value={(currentPage - 1) * 25 + 25}
//                 className="w-96 m-auto"
//                 color="warning"
//               />
//               <p className="mt-8">Enter the name of reporting year</p>
//               <Input
//                 type="text"
//                 placeholder="Name of the Reporting Year"
//                 className="mt-4"
//                 id="year"
//                 defaultValue={formData_02.Reporting_Year}
//                 invalid={ValidateCondition && !formData_02.Reporting_Year}
//                 invalidText={Validations.Reporting_YearError}
//                 onChange={(e) => {
//                   setFormData_02({
//                     ...formData_02,
//                     Reporting_Year: e.target.value,
//                   });
//                 }}
//               />
//               <p className="mt-4">Enter the start date</p>
//               {/* <DatePicker
//                 className="mt-4"
//                 value={formData_02.Date}
//                 onChange={(e) =>
//                   setFormData_02({ ...formData_02, Date: e.toLocaleString() })
//                 }
//                 datePickerType="single"
//               /> */}
//               <p className="mt-4">Enter the reporting template</p>
//               <Select
//                 className=" max-w-3xl mt-4"
//                 placeholder="Choose One Template"
//                 invalid={ValidateCondition && !formData_02.Date}
//                 invalidText={Validations.DateError}
//                 onChange={(e) =>
//                   setFormData_02({ ...formData_02, Date: e.target.value })
//                 }
//               >
//                 {dropdown1.map((dropdown1) => (
//                   <SelectItem key={dropdown1.value} value={dropdown1.value}>
//                     {dropdown1.label}
//                   </SelectItem>
//                 ))}
//               </Select>
//               <div className="flex justify-between ">
//                 <Button onClick={prevPage} className="mt-10">
//                   Previous
//                 </Button>
//                 {currentPage < 4 ? (
//                   <Button className="mt-10" color="warning" onClick={nextPage}>
//                     Next
//                   </Button>
//                 ) : (
//                   <Button className="mt-10" color="danger">
//                     Submit
//                   </Button>
//                 )}
//               </div>
//             </div>
//           </>
//         );
//       case 3:
//         return (
//           <>
//             <div className="p-20 max-w-2xl m-auto">
//               <div className="flex flex-col justify-center p-10">
//                 <div className="flex justify-center">
//                   <Image
//                     width={100}
//                     alt="NextUI hero Image"
//                     src="/Assets/logo.svg"
//                   />
//                 </div>
//                 <p className="font-medium text-3xl text-center mt-8">
//                   Choose an Approval Flow
//                 </p>
//                 <p className="mt-6">
//                   Add a common approval flow for your team to your data points
//                   to minimize errors in your reporting.
//                 </p>
//               </div>
//               <Progress
//                 value={(currentPage - 1) * 25 + 25}
//                 className="w-96 m-auto mb-8"
//                 color="secondary"
//               />
//               <Select
//                 className="max-w-3xl mt-4"
//                 placeholder="No Approval Flow"
//                 onChange={handleFlowSelect}
//               >
//                 {dropdown2.map((option) => (
//                   <SelectItem key={option.id} value={option.id}>
//                     {option.label}
//                   </SelectItem>
//                 ))}
//               </Select>
//               {selectedFlow && (
//                 <div className="flex justify-center">
//                   <img
//                     src={images[`flow${selectedFlow}`]}
//                     alt="Selected Flow"
//                     className="w-96 h-96"
//                   />
//                 </div>
//               )}
//               <p className="mt-8 m-auto">
//                 <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
//                 When there's no approval flow, your team enters data directly
//                 into the report without any additional checks. This isn't
//                 recommended because it raises the risk of errors in your data
//                 entry.
//               </p>
//               <div className="flex justify-between ">
//                 <Button onClick={prevPage} className="mt-10">
//                   Previous
//                 </Button>
//                 {currentPage < 4 ? (
//                   <Button
//                     className="mt-10"
//                     color="secondary"
//                     onClick={nextPage}
//                   >
//                     Next
//                   </Button>
//                 ) : (
//                   <Button className="mt-10">Submit</Button>
//                 )}
//               </div>
//             </div>
//           </>
//         );
//       case 4:
//         return (
//           <>
//             <div className="p-20 max-w-xl m-auto">
//               <div className="flex justify-center p-10">
//                 <Image
//                   width={100}
//                   alt="NextUI hero Image"
//                   src="/Assets/logo.svg"
//                 />
//               </div>
//               <p className="font-medium text-3xl text-center">
//                 Invite your team to begin your ESG Audyting
//               </p>
//               <Progress
//                 value={(currentPage - 1) * 25 + 25}
//                 className="w-96 m-auto mt-8"
//                 color="primary"
//               />
//               <p className="m-auto mt-6">
//                 User will be added to Audyt Organization Name once the user
//                 accepts the invitation sent on the given email address.
//               </p>
//               <p className="mt-8">Enter Multiple email address</p>
//               <Input
//                 type="email"
//                 placeholder="Enter email address"
//                 className="mt-6"
//                 invalid={ValidationConditions && VerificationError !== null}
//                 invalidText={VerificationError}
//                 value={inputValue}
//                 onChange={handleInputChange}
//                 onKeyDown={handleKeyPress}
//               />
//               <div className="flex justify-between ">
//                 <Button onClick={prevPage} className="mt-10">
//                   Previous
//                 </Button>
//                 {currentPage < 4 ? (
//                   <Button className="mt-10" color="warning" onClick={nextPage}>
//                     Next
//                   </Button>
//                 ) : (
//                   <Link to={"/thank-you"}>
//                     <Button color="primary" className="mt-10">
//                       Setup your Audyt Organization
//                       <FontAwesomeIcon icon={faArrowRight} />
//                     </Button>
//                   </Link>
//                 )}
//               </div>
//             </div>
//           </>
//         );
//       default:
//         return null;
//     }
//   };

//   return <>{renderForm()}</>;
// }
