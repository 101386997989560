  import { Input, useDisclosure } from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SearchIcon from "../../Images/Search";
import {
  GetAllMedia,
  getDataWithFilterUrl,
} from "../../controllers/APIController";
import Notification from "../Notification/Notification";
import ShowSearchResults from "./ShowSearchResults";

const Header = () => {
  const { _orgId } = useParams();

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;

  const [searchedData, setSearchedData] = useState([]);
  const [InputText, setInputText] = useState("");
  const [IsOpen, setIsOpen] = useState(false);

  const [showResults, setShowResults] = useState(true);

  const [searchResults, setSearchResults] = useState([]);
  const resultsRef = useRef(null);
  const Navigate = useNavigate();

  // const theme = sessionStorage.getItem("Theme")

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && InputText.trim() !== "") {
      Navigate(`/${_orgId}/search/${InputText}`);
      window.location.reload();
      setInputText("");
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    let dataList = [];
    const url = `filters[$or][0][Organization][id][$eqi]=${OrgInfo}&filters[$or][1][Category][$eqi]=Reference Data`;
    getDataWithFilterUrl("metrics", url)
      .then((data) => {
        data?.data?.forEach((element) => {
          dataList.push({
            id: element.id,
            type: "Metric",
            Name: element?.attributes?.Name,
            category: element?.attributes?.Category,
            description: element?.attributes?.Description || "---",
          });
        });
        GetAllMedia().then((data) => {
          data?.forEach((element) => {
            dataList.push({
              id: element.id,
              type: "Evidence",
              Name: element?.name,
              url: element?.url,
            });
          });
          setSearchedData(dataList);
        });
      })
      .catch((err) => err);
  }, []);

  const HandleChange = (event) => {
    const TextValue = event.target.value?.trim();
    setInputText(TextValue);
    const filterDataList =
      searchedData &&
      searchedData?.filter((data) =>
        data.Name.toLowerCase().includes(TextValue.toLowerCase())
      );

    setSearchResults(filterDataList);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        setShowResults(!showResults);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [resultsRef]);

  useEffect(() => {
    setShowResults(true);
  }, [InputText]);

  console.log(showResults);
  return (
    <div>
      <div className="flex h-[60px] items-center justify-end border-b border-divider px-6">
        <div className="relative w-full ">
          <div className="w-full relative">
            <Input
              classNames=""
              onChange={HandleChange}
              value={InputText}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleKeyPress(event);
                }
              }}
              placeholder="Search by files, tags, content..."
              className="px-8"
              startContent={
                <SearchIcon
                  className={`text-large text- pointer-events-none flex-shrink-0 `}
                />
              }
            />
          </div>
          {InputText !== "" && showResults && (
            <ShowSearchResults
              InputText={InputText}
              _orgId={_orgId}
              resultsRef={resultsRef}
              searchResults={searchResults}
              setInputText={setInputText}
            />
          )}
        </div>
        <div className="flex flex-row items-center gap-3  h-14">
          <span className="flex h-8 items-center gap-2 rounded-md px-2 text-sm text-grey-black hover:bg-grey-200 active:bg-grey-200 pt-4 cursor-pointer ">
            <Notification />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Header;
