import { Avatar, Button, Divider, Textarea } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import {
  UpdateOneData,
  getDataWithFilterUrl,
} from "../../controllers/APIController";
import { useNavigate } from "react-router-dom";
import { Colors, numColors } from "../../utils/constant";
import { DateFormator, formatDate } from "../../utils/helper";

const SingleComment = ({ userId, userEmail, userRole, text, textDate }) => {
  const commentText =
    text ||
    `No description is available at the moment. Please check back later for updates.`;

  const commentDate = DateFormator(textDate);
  return (
    <div className="flex">
      <div className="p-2">
        <Avatar
          showFallback
          className="h-8 w-8 cursor-pointer"
          color={Colors[userId % numColors]}
          name={userEmail.toUpperCase().slice(0, 1)}
          src="https://images.unsplash.com/broken"
        />
      </div>
      <div className="py-2 w-full">
        <div className="flex items-center justify-between gap-2 w-full">
          <span className="flex items-center gap-2">
            <div className="flex flex-col">
              <span className="font-medium text-sm">{userEmail}</span>
              <span className="text-grey-600 font-medium text-xs">
                {userRole}
              </span>
            </div>
          </span>
          <span className="text-grey-600 text-sm">
            {formatDate(commentDate)}
          </span>
        </div>
        <div className="text-sm text-warmGray-700 mt-1">{commentText}</div>
      </div>
    </div>
  );
};
const WorkFlowComment = ({ CollectionInfo, responseInfo }) => {
  const [commentText, setCommentText] = useState("");
  const [validComment, setValidText] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const navigate = useNavigate();

  const is2StepAppoverType =
    CollectionInfo?.attributes?.WorkFlow === "2-Step Approval";

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const UserId = UserLogged && UserLogged.user.id;
  const responseStatus = responseInfo?.attributes?.Status;

  const isVisiable =
    (responseStatus === "Created" && userRole === "Approver") ||
    (responseStatus === "Approved" && userRole === "Reviewer") ||
    (responseStatus === "Rejected by Reviewer" && userRole === "Approver");

  const CurrrentComments = responseInfo?.attributes?.Comments?.comments || [];

  const SubmitHandler = async () => {
    if (commentText.trim() === "") {
      setValidText("Comment field is required");
      return;
    }

    const newStatus = is2StepAppoverType
      ? responseStatus === "Approved"
        ? "Done"
        : "Approved"
      : "Done";
    const previousComments =
      (responseInfo?.attributes?.Comments?.comments && [
        ...responseInfo?.attributes?.Comments?.comments,
      ]) ||
      [];
    const newComment = {
      author_id: UserId,
      author: userEmail,
      text: commentText,
      role: userRole,
      date: Date.now(),
    };

    const newComments = [newComment, ...previousComments];
    const publishData = {
      Status: newStatus,
      Comments: {
        comments: newComments,
      },
    };
    let result = await UpdateOneData(
      "responses",
      responseInfo?.id,
      publishData
    );

    navigate(-1);
  };
  const RejectHandler = async () => {
    if (commentText.trim() === "") {
      setValidText("Comment field is required");
      return;
    }
    const newStatus =
      responseStatus === "Created"
        ? "Rejected"
        : responseStatus === "Rejected by Reviewer"
        ? "Rejected"
        : "Rejected by Reviewer";

    const previousComments =
      (responseInfo?.attributes?.Comments?.comments && [
        ...responseInfo?.attributes?.Comments?.comments,
      ]) ||
      [];
    const newComment = {
      author_id: UserId,
      author: userEmail,
      text: commentText,
      role: userRole,
      date: Date.now(),
    };

    const newComments = [newComment, ...previousComments];

    const publishData = {
      Status: newStatus,
      Comments: {
        comments: newComments,
      },
    };

    let result = await UpdateOneData(
      "responses",
      responseInfo?.id,
      publishData
    );

    navigate(-1);
  };

  useEffect(() => {
    const userFilteredUrl = "filters[id][$eqi]=" + UserId;
    getDataWithFilterUrl("users", userFilteredUrl)
      .then((data) => {
        setUserEmail(data[0]?.email);
        setUserRole(data[0]?.role?.name);
      })
      .catch((err) => console.log(err));
  }, []);

  // console.log(
  //   CollectionInfo,
  //   responseInfo,
  //   is2StepAppoverType,
  //   userRole,
  //   userEmail,
  //   CurrrentComments
  // );

  if (CurrrentComments.length === 0) return;
  return (
    <>
      <div className={`${!isVisiable && "hidden"}`}>
        <Textarea
          className=""
          label="Add Comment"
          key="add-comment"
          isRequired
          variant="faded"
          labelPlacement="outside"
          value={commentText}
          placeholder=" "
          onChange={(event) => {
            setCommentText(event.target.value);
          }}
          isInvalid={validComment}
          errorMessage={validComment}
        />
        <div className="flex justify-end gap-2 my-8">
          <Button
            color="danger"
            variant="ghost"
            size="md"
            onClick={RejectHandler}
          >
            Reject
          </Button>
          <Button
            color="secondary"
            variant="ghost"
            size="md"
            onClick={SubmitHandler}
          >
            {userRole === "Approver" ? "Approved" : "Reviewed"}
          </Button>
        </div>
      </div>
      {/* <p>
        <span className="font-medium">Current Status :</span> <span className="text-green font-medium">Created</span>
      </p> */}
      <div className="mb-4 ">
        <p className="font-medium text-lg mb-2">Comments</p>
        <div className="custom-dropdown-menu">
          {CurrrentComments?.map((item) => (
            <>
              <SingleComment
                userId={item?.author_id}
                text={item?.text}
                userEmail={item?.author}
                userRole={item?.role}
                textDate={new Date(item.date)}
              />
              <Divider className="" />
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default WorkFlowComment;
