import { Button, Image } from "@nextui-org/react";
import React from "react";
import logo from "../../Images/logo.svg";
import { useNavigate } from "react-router-dom";

const LinkExpire = () => {
  const navigate = useNavigate();

  const handleResetLink = () => {
    navigate("/auth/reset-password");
  };

  return (
    <div className="flex h-screen items-center justify-center bg-gray-50">
      <main className="mx-auto w-full max-w-md p-6 bg-white shadow-md rounded-md">
        <div className="flex justify-center">
          <Image width={60} alt="Company logo" src={logo} />
        </div>
        <h1 className="mt-6 text-center text-2xl font-bold text-gray-800">
          Reset Link Expired
        </h1>
        <p className="mt-4 text-center text-gray-600">
          The reset password link has expired. Please generate a new one to proceed.
        </p>
        <div className="mt-6">
          <Button
            id="CreateAccount"
            radius="sm"
            onClick={handleResetLink}
            className="w-full bg-blue-600 text-white hover:bg-blue-700"
          >
            Generate New Reset Link
            <lord-icon
              src="https://cdn.lordicon.com/vduvxizq.json"
              trigger="hover"
              target="#CreateAccount"
              colors="primary:#ffffff"
              style={{ width: "18px", height: "20px", marginLeft: "8px" }}
            />
          </Button>
        </div>
      </main>
    </div>
  );
};

export default LinkExpire;
