import {
  Avatar,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React from "react";
import Charts from "./Charts";
import { Chart } from "@ant-design/plots/es/core/base/chart";
import NullData from "../../components/ErrorPage/NullData";
import { StringLimit } from "../../controllers/APIController";

const all_emissions = {};
const table1header = [];
const table1Data = [
  {
    id: "1",
    country: "AUS",
    scope1: "100",
    scope2: "200",
    scope3: "300",
  },
  {
    id: "2",
    country: "BRA",
    scope1: "400",
    scope2: "500",
    scope3: "600",
  },
  {
    id: "3",
    country: "CHE",
    scope1: "700",
    scope2: "800",
    scope3: "900",
  },
  {
    id: "4",
    country: "DNK",
    scope1: "100",
    scope2: "200",
    scope3: "300",
  },
  {
    id: "5",
    country: "IND",
    scope1: "100",
    scope2: "200",
    scope3: "300",
  },
  {
    id: "6",
    country: "KEN",
    scope1: "100",
    scope2: "200",
    scope3: "300",
  },
  {
    id: "7",
    country: "JPN",
    scope1: "100",
    scope2: "200",
    scope3: "300",
  },
];
const TableContent1 = () => {
  return (
    <Table
      removeWrapper
      aria-label="Example static collection table"
      isStriped={true}
    >
      <TableHeader>
        <TableColumn className="w-[50%]">Country</TableColumn>
        <TableColumn>Scope 1</TableColumn>
        <TableColumn>Scope 2</TableColumn>
        <TableColumn>Scope 3</TableColumn>
      </TableHeader>
      <TableBody emptyContent={<NullData />}>
        {table1Data?.map((data) => {
          return (
            <TableRow
              key={data?.id}
              className="cursor-pointer hover:bg-grey-200"
            >
              <TableCell className="text-grey-600 font-medium">
                {data?.country}
              </TableCell>
              <TableCell className="text-black text-xs font-medium">
                {data?.scope1}
              </TableCell>
              <TableCell className="text-black text-xs font-medium">
                {data?.scope2}
              </TableCell>
              <TableCell className="text-black text-xs font-medium">
                {data?.scope3}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const Scope1Stats = () => {
  return (
    <div className="flex flex-col gap-1 text-sm">
      <div className="flex items-center justify-between font-medium">
        <span>Scope 1</span>
        <span>23,232,343.02</span>
      </div>
      <Divider />
      <div className="flex flex-col pl-4">
        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-between text-gray text-sm">
            <span>Fugitive emission</span>
            <span>2,343.02</span>
          </div>
          <Divider />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-between text-gray text-sm">
            <span>Mobile combustion</span>
            <span>2,343.02</span>
          </div>
          <Divider />
        </div>

        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-between text-gray text-sm">
            <span>Stationary combustion</span>
            <span>2,343.02</span>
          </div>
          <Divider />
        </div>
      </div>
    </div>
  );
};
const Scope2Stats = () => {
  return (
    <div className="flex flex-col gap-1 text-sm">
      <div className="flex items-center justify-between font-medium">
        <span>Scope 2</span>
        <span>34,122.332</span>
      </div>
      <Divider />
      <div className="flex flex-col pl-4">
        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-between text-gray text-sm">
            <span>Purchased cooling</span>
            <span>4,3432.34</span>
          </div>
          <Divider />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-between text-gray text-sm">
            <span>Purchased electricity</span>
            <span>2,343.02</span>
          </div>
          <Divider />
        </div>

        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-between text-gray text-sm">
            <span>Purchased heat</span>
            <span>3,343.02</span>
          </div>
          <Divider />
        </div>
      </div>
    </div>
  );
};
const Scope3Stats = () => {
  return (
    <div className="flex flex-col gap-1 text-sm">
      <div className="flex items-center justify-between font-medium">
        <span>Scope 3</span>
        <span>89,223.9232</span>
      </div>
      <Divider />
      <div className="flex flex-col pl-4">
        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-between text-gray text-sm">
            <span>Purchased heat</span>
            <span>3,343.02</span>
          </div>
          <Divider />
        </div>
      </div>
    </div>
  );
};
const TotalStats = () => {
  return (
    <div className="flex flex-col gap-1 text-sm my-4">
      <div className="flex items-center justify-between font-medium">
        <span>Total</span>
        <span>89,223.9232</span>
      </div>
    </div>
  );
};
const CardGrid = ({ card1, card2, card3, card4 }) => {
  return (
    <div className="flex items-stretch min-h-[80vh] gap-4">
      <div className="flex w-[20%]">
        <Card className="w-full px-2 py-4" shadow="md">
          <p className="font-semibold">Emission Insights (mtCO2e)</p>
          <p className="text-3xl font-semibold my-8 px-4">1,20,3443</p>

          <div className="flex flex-col gap-4 my-8">
            <p className="font-semibold text-gray-dark">Scope type</p>
            <div className="flex flex-col gap-2 px-2">
              <div className="flex flex-col gap-2 font-medium">
                <div className="flex items-center justify-between">
                  <span className="text-grey-600">Scope 1</span>
                  <span>1,223,232</span>
                </div>
                <Divider />
              </div>
              <div className="flex flex-col gap-2 font-medium">
                <div className="flex items-center justify-between">
                  <span className="text-grey-600">Scope 2</span>
                  <span>46,342.764</span>
                </div>
                <Divider />
              </div>
              <div className="flex flex-col gap-2 font-medium">
                <div className="flex items-center justify-between">
                  <span className="text-grey-600">Scope 3</span>
                  <span>89,221.32</span>
                </div>
                <Divider />
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="flex flex-col gap-8 flex-1">
        <div className="h-[40%]">
          <Card className="w-full px-2 py-8 h-full" shadow="md">
            <Charts type={"line"} />
          </Card>
        </div>
        <div className="flex items-center gap-8 flex-1">
          <Card className="w-[45%] px-4 py-4 h-full" shadow="md">
            <div className="flex items-center justify-between font-medium text-grey-600 text-sm mb-4">
              <p>Scope/Source</p>
              <p>Emissions</p>
            </div>
            <div className="flex flex-col gap-2">
              <Scope1Stats />
              <Scope2Stats />
              <Scope3Stats />
            </div>
            <TotalStats />
          </Card>
          <Card className="flex-1 px-2 py-4 h-full" shadow="md">
            <TableContent1 />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CardGrid;
