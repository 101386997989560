import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import React from "react";

const TableChart = ({ TableData }) => {
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 10;
  const data = TableData;

  const pages = Math.ceil(data?.length / rowsPerPage);

  const columns = [];

  TableData?.length > 0 &&
    Object.keys(TableData[0]).forEach((value) => {
      columns.push({ key: value, label: value });
    });

  // console.log("TableData->", data);
  // console.log("TableColumn->", columns);

  const start = (page - 1) * rowsPerPage;
  const end = start + rowsPerPage;

  const PaginatedData = data.slice(start, end);

  return (
    <div className="flex items-center justify-center my-2 w-full">
      <Table
        aria-label="Example table with dynamic content"
        bottomContent={
          <div className="flex w-full justify-center">
            <Pagination
              isCompact
              showControls
              showShadow
              color="secondary"
              page={page}
              total={pages}
              onChange={(page) => setPage(page)}
            />
          </div>
        }
        removeWrapper
      >
        <TableHeader columns={columns}>
          {(column) => (
            <TableColumn key={column.key}>{column.label}</TableColumn>
          )}
        </TableHeader>
        <TableBody>
          {PaginatedData?.map((item, index) => {
            return (
              <TableRow key={index}>
                {columns?.map((column) => (
                  <TableCell key={column.key}>
                    {item?.[column?.label] || "--"}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
  
};

export default TableChart;
