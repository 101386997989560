import { Progress } from "@nextui-org/react";
import React, { useState } from "react";
import ReportWizardPart1 from "./ReportWizardPart1";
import ReportWizardPart2 from "./ReportWizardPart2";
import { ProgessColors } from "../../utils/constant";
import BreadCrumbsPage from "../../components/BreadCrumbsPage";
import ReportWizardPart3 from "./ReportWizardPart3";

const CreateReportWizard = () => {
  const [currentPage, setCurrrentPage] = useState(0);
  const [DataList, setDataList] = useState({
    Name: "",
    "Fiscal Year": "",
    TemplateIndex: null,
  });
  const totalStep = 3;

  const CurrentComp = () => {
    
    switch (currentPage) {
      case 0:
        return (
          <ReportWizardPart1
            currentPage={currentPage}
            setCurrrentPage={setCurrrentPage}
            DataList={DataList}
            setDataList={setDataList}
          />
        );
      case 1:
        return (
          <ReportWizardPart2
            currentPage={currentPage}
            setCurrrentPage={setCurrrentPage}
            DataList={DataList}
            setDataList={setDataList}
          />
        );
      case 2:
        return (
          <ReportWizardPart3
            currentPage={currentPage}
            setCurrrentPage={setCurrrentPage}
            DataList={DataList}
            setDataList={setDataList}
          />
        );
      default:
        return null;
    }
  };

  return (
    <BreadCrumbsPage>
      <div>
        <div className="flex flex-col w-full mx-auto">
          <div className="flex items-center justify-center my-8">
            <Progress
              color={ProgessColors[currentPage]}
              size="sm"
              aria-label="Loading..."
              value={33 * currentPage}
              className="flex justify-end w-[50%]"
            />
          </div>
          <div className="mt-8">{CurrentComp()}</div>
        </div>
      </div>
    </BreadCrumbsPage>
  );
};

export default CreateReportWizard;
