import React, { useState, useEffect } from "react";
import { Textarea, Image, Chip } from "@nextui-org/react";
import { RightArrow } from "../../Images/RightArrow";
import { colorPicker } from "../../controllers/basicControllers.js";
import Logo from "../../Images/logo.svg";
import { suggestions } from "./Suggestions";

const InitialSearch = ({
  inputValue,
  setInputValue,
  handleKeyPress,
  loading,
}) => {
  const [randomSuggestions, setRandomSuggestions] = useState([]);
  const [showMore, setShowMore] = useState(false);

  const shuffleArray = (array) => {
    return array
      .map((item) => ({ ...item, sortValue: Math.random() }))
      .sort((a, b) => a.sortValue - b.sortValue)
      .map((item) => item);
  };

  useEffect(() => {
    const shuffledSuggestions = shuffleArray(suggestions);
    setRandomSuggestions(shuffledSuggestions.slice(0, 8));
  }, []);

  const sliceData = showMore
    ? randomSuggestions
    : randomSuggestions?.slice(0, 5);

  return (
    <>
      <div className="flex flex-col gap-4 justify-center items-center h-full">
        <div className="flex gap-4 items-center">
          <h2 className="text-3xl">What can I help with?</h2>
        </div>
        <div className="max-w-xl w-full pt-2">
          <Textarea
            disabled={loading}
            style={{ padding: "7px" }}
            size="lg"
            startContent={
              <Image
                src={Logo}
                width={40}
                height={30}
                radius="full"
                className="text-large text-default-400 pointer-events-none mt-1"
              />
            }
            endContent={
              <div
                className={`rounded-full p-2 m-1 h-full flex justify-center ${
                  inputValue && !loading ? "bg-black" : "bg-default-200"
                }`}
              >
                {loading ? (
                  <lord-icon
                    src="https://cdn.lordicon.com/lqxfrxad.json"
                    trigger="loop"
                    delay="1000"
                    style={{ width: "25px", height: "25px" }}
                  ></lord-icon>
                ) : (
                  <RightArrow textdisabledState={inputValue} />
                )}
              </div>
            }
            placeholder="Message"
            value={inputValue}
            onChange={(event) => {
              setInputValue(event.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                if (event.shiftKey) {
                  setInputValue((prev) => prev + "\n");
                } else {
                  handleKeyPress(event);
                }
              }
            }}
          />
        </div>
        <div className="mt-4">
          <div className="flex flex-wrap gap-2 justify-center max-w-xl">
            {sliceData.map((data, index) => {
              return (
                <Chip
                  onClick={() => setInputValue(data?.value)}
                  color={colorPicker(index + 1)}
                  size="md"
                  className=""
                  variant="flat"
                >
                  <div className="flex cursor-pointer">
                    {data?.icon}
                    {data?.name}
                  </div>
                </Chip>
              );
            })}
            {!showMore && (
              <Chip
                size="md"
                color={colorPicker(1)}
                variant="flat"
                onClick={() => {
                  setShowMore(true);
                }}
                className="cursor-pointer"
              >
                More
              </Chip>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InitialSearch;
