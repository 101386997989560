// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawer {
    height: 100%;
    transform: translateX(100%); /* Hide the drawer initially off-screen */
    transition: transform 0.3s ease; /* Smooth animation */
  }
  
  .drawer.open {
    transform: translateX(0); /* Slide the drawer in */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Haya/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,2BAA2B,EAAE,yCAAyC;IACtE,+BAA+B,EAAE,qBAAqB;EACxD;;EAEA;IACE,wBAAwB,EAAE,wBAAwB;EACpD","sourcesContent":[".drawer {\n    height: 100%;\n    transform: translateX(100%); /* Hide the drawer initially off-screen */\n    transition: transform 0.3s ease; /* Smooth animation */\n  }\n  \n  .drawer.open {\n    transform: translateX(0); /* Slide the drawer in */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
