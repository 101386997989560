import { Button } from "@nextui-org/react";
import React from "react";
import { ProgessColors } from "../../utils/constant";
import { usePDF } from "@react-pdf/renderer";

const ReportWizardPart3 = ({
  DataList,
  setDataList,
  currentPage,
  setCurrrentPage,
}) => {
  const { TemplateComponent: Component } = DataList;
  const [instance, updateInstance] = usePDF({ document: <Component /> });

  const clickHandler = (urlLink) => {
    if (!urlLink) return;
    console.log(urlLink);

    window.open(urlLink, "_blank");
  };

  let val = "";
  if (instance.loading) val = "loading";
  if (instance.error) val = "Something went wrong";
  else val = instance.url;
  //
  console.log(DataList, instance, val);

  return (
    <div>
      <div className="min-h-[500px]">
        <div className="flex flex-col gap-2">
          <p className="text-2xl font-medium">View the Report</p>
          <p>
            Here is the ESG (Environmental, Social, and Governance) report based on the selected templates and data. Review the findings and insights to understand the organization's impact in these areas. You can also print the report for your records or for distribution to stakeholders.
          </p>
        </div>

        <div className="flex items-center justify-center my-8">
          <div style={{ width: "80%", height: "100vh" }}>
            <iframe
              src={val}
              width="100%"
              height="100%"
              title="PDF Viewer"
              frameBorder="0"
            />
          </div>
        </div>
      </div>
      <div className={`w-full flex items-center my-8 gap-4 justify-end`}>
        <Button
          color={ProgessColors[currentPage]}
          variant="light"
          className={`text-center cursor-pointer ${
            currentPage === 0 && "hidden"
          }`}
          onClick={() => {
            setCurrrentPage(currentPage - 1);
          }}
        >
          Previous
        </Button>
        <Button
          color={ProgessColors[currentPage]}
          className={`text-center cursor-pointer`}
          onClick={() => clickHandler(val)}
        >
          Download
        </Button>
      </div>
    </div>
  );
};

export default ReportWizardPart3;
