import React from "react";
import Charts from "./Charts";
import { Card } from "@nextui-org/react";

const WaterInsight = () => {
  return (
    <div className="my-8">
      {/* <Charts type="bar" /> */}
      <div className="w-full flex flex-col gap-4">
        <div className="flex flex-row gap-4">
          <div className="w-1/4">
            <Card>
            </Card>
          </div>
          <div className="w-3/4"> <Card>ss</Card></div>
        </div>
        <div className="flex flex-row gap-4">
          <div className="w-1/4">
            <Card>ss</Card>
          </div>
          <div className="w-3/4"> <Card>ss</Card></div>
        </div>
        <div className="flex flex-row gap-4">
          <div className="w-1/4">
            <Card>ss</Card>
          </div>
          <div className="w-3/4"> <Card>ss</Card></div>
        </div>
        <div className="flex flex-row gap-4">
          <div className="w-1/4">
            <Card>ss</Card>
          </div>
          <div className="w-3/4"> <Card>ss</Card></div>
        </div>
      </div>
    </div>
  );
};

export default WaterInsight;
