export const ChartErrorComp = ({ errorMessage }) => {
  return (
    <div className="flex w-full h-full">
      {" "}
      <div className="flex w-full flex-col gap-2 items-center justify-center">
        {" "}
        <p className="text-2xl font-semibold">Error Occurred</p>{" "}
        <p className="text-gray font-medium">{errorMessage}</p>{" "}
      </div>{" "}
    </div>
  );
};
