import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import ApiModal from "./ApiModal";
import {
  DeleteOnedata,
  getDataWithFilterUrl,
} from "../../../controllers/APIController";
import { DateFormatorDD } from "../../../utils/helper";
import ApiEditModal from "./ApiEditModal";
import LoadingPage from "../../../components/Loader/LoadingPage";
import NullData from "../../../components/ErrorPage/NullData";

const ApiKey = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onOpenChange: onOpenChangeEdit,
  } = useDisclosure();

  const [selectedId, setSelectedId] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [apiKeyData, setApiKeyData] = useState([]);
  const [reRender, setReRender] = useState(true);

  const UserLogged = JSON.parse(sessionStorage.getItem("userData"));
  const OrgInfo = UserLogged && UserLogged.user.LastOrgId;
  const orgFilterUrl = "filters[Organization][id][$eqi]=" + OrgInfo;

  const UserEmail = UserLogged && UserLogged?.user?.email;

  const submitHandler = () => {
    setReRender((pre) => !pre);
  };
  const createHandler = () => {
    onOpen();
  };
  const deleteKeyHandler = async (id) => {
    try {
      const result = await DeleteOnedata("keys", id);
      setReRender((pre) => !pre);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDataWithFilterUrl("keys", orgFilterUrl)
      .then((data) => {
        const responseData = data.data;
        const filteredResponseData = responseData.filter((item) => {
          return (
            item?.attributes?.users_permissions_user?.data?.attributes
              ?.email === UserEmail
          );
        });
        setApiKeyData(filteredResponseData);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reRender]);

  const headers = ["Name", "Key", "Created At", "Responses", "Actions"];
  const dummyAPI = "************************";
  const ItemPerPage = 20;
  const totalPage = Math.ceil(apiKeyData.length / ItemPerPage);

  return (
    <div>
      {isOpen && (
        <ApiModal
          onSubmitHandler={submitHandler}
          isOpen={isOpen}
          onOpen={onOpen}
          onOpenChange={onOpenChange}
          id={Date.now()}
        />
      )}
      {isOpenEdit && (
        <ApiEditModal
          onSubmitHandler={submitHandler}
          isOpen={isOpenEdit}
          onOpen={onOpenEdit}
          onOpenChange={onOpenChangeEdit}
          id={selectedId}
        />
      )}
      <div className="my-8">
      <p>
          Manage your API keys for emissions metrics submissions here. Create, edit, or delete your API keys as needed.
        </p>
      </div>
      <div className="flex justify-end cursor-pointer">
        <Button color="danger" onClick={createHandler}>
          Create API-Key
        </Button>
      </div>
      <LoadingPage isLoading={isLoading}>
        <div className="mt-8">
          <Table removeWrapper selectionMode="single">
            <TableHeader>
              {headers.map((header, headerIndex) => (
                <TableColumn key={headerIndex}>{header}</TableColumn>
              ))}
            </TableHeader>
            <TableBody
              emptyContent={
                <NullData
                  title={`No Api key found. Click on "create" to make a new key`}
                />
              }
            >
              {apiKeyData.map((item, index) => {
                const lowerPageLimit = (currentPage - 1) * ItemPerPage;
                const upperPageLimit = currentPage * ItemPerPage;
                if (index < lowerPageLimit || index >= upperPageLimit)
                  return null;

                return (
                  <TableRow
                    key={item.id}
                    onClick={() => {
                      setSelectedId(item.id);
                      onOpenEdit();
                    }}
                    className="cursor-pointer"
                  >
                    <TableCell>{item?.attributes?.name}</TableCell>
                    <TableCell>{dummyAPI}</TableCell>
                    <TableCell>
                      {DateFormatorDD(item?.attributes?.createdAt)}
                    </TableCell>
                    <TableCell>{item?.attributes?.Logs?.length || 0}</TableCell>

                    <TableCell>
                      <div className={`flex gap-4 items-center cursor-pointer`}>
                        <Dropdown>
                          <DropdownTrigger>
                            <div className="cursor-pointer">
                              <Tooltip size="sm" content="Delete Metric">
                                <lord-icon
                                  src="https://cdn.lordicon.com/wpyrrmcq.json"
                                  trigger="hover"
                                  colors={`primary #000000`}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                  }}
                                ></lord-icon>
                              </Tooltip>
                            </div>
                          </DropdownTrigger>
                          <DropdownMenu
                            aria-label="Static Actions"
                            selectionMode="single"
                            onSelectionChange={(event) => {
                              deleteKeyHandler(item?.id);
                            }}
                          >
                            <DropdownItem
                              key="delete"
                              color="danger"
                              variant="flat"
                            >
                              Delete {item?.attributes?.name}?
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        {apiKeyData.length > 0 && (
          <div className="mt-4">
            {apiKeyData.length > 0 && (
              <div className="w-full mt-8 flex items-center justify-center">
                <Pagination
                  showControls
                  total={totalPage}
                  color="secondary"
                  page={currentPage}
                  onChange={setCurrentPage}
                />
              </div>
            )}
          </div>
        )}
      </LoadingPage>
    </div>
  );
};

export default ApiKey;
