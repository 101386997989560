import { Tab, Tabs } from "@nextui-org/react";
import React from "react";

const ContentInfo = ({ overviewText = "", guidanceText = "" }) => {
 
  return (
    <>
      <div className="flex flex-col gap-1 text-sm my-2">
        <p className="flex font-medium">Overview</p>
        <p className="text-gray">{overviewText}</p>
      </div>
      <div className="flex flex-col gap-1 text-sm my-2">
        <p className="flex font-medium">Guidance</p>
        <p className="text-gray">{guidanceText}</p>
      </div>
    </>
  );
};
const MetricFrameworkInfo = ({ CollectionInfo, orgFrameworkDetails }) => {
  //   console.log(CollectionInfo, orgFrameworkDetails);
  return (
    <div>
      <Tabs
        aria-label="Dynamic tabs"
        items={orgFrameworkDetails}
        variant="light"
      >
        <Tab key="generic" title="Generic">
          <ContentInfo
            overviewText={CollectionInfo?.attributes?.Overview?.Generic || "--"}
            guidanceText={CollectionInfo?.attributes?.Guidance?.Generic || "--"}
          />
        </Tab>
        {orgFrameworkDetails?.map((item) => (
          <Tab key={item.id} title={item.attributes?.Name}>
            <ContentInfo
              overviewText={
                CollectionInfo?.attributes?.Overview?.[item.attributes?.Name] ||
                "--"
              }
              guidanceText={
                CollectionInfo?.attributes?.Guidance?.[item.attributes?.Name] ||
                "--"
              }
            />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default MetricFrameworkInfo;
