import { useMemo } from "react";

export default function useGetUserDetail() {
  const UserLogged = useMemo(() => {
    return JSON.parse(sessionStorage.getItem("userData"));
  }, []); // Runs only when the session storage data changes.

  const userDetails = useMemo(() => {
    return UserLogged && UserLogged.user;
  }, [UserLogged]);

  return { userDetails };
}
