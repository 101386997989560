export class ThreadManager {
  constructor(setMessages, app_id, collection_name) {
    this.ws = new WebSocket(
      `${process.env.REACT_APP_HAYA_WS_ADDRESS}/haya/ws/stream`
    );
    this.init(setMessages);
    this.app_id = app_id;
    this.collection_name = collection_name;
  }

  static getInstance(setMessages, app_id, collection_name) {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.close();
    }
    if (!this.instance) {
      this.instance = new ThreadManager(setMessages, app_id, collection_name);
    }
    return this.instance;
  }

  init(setMessages) {
    this.ws.onopen = () => {
      console.log("WebSocket connection established.");
    };

    this.ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log(message);

      if (!message?.message_id) {
        return;
      }
      setMessages((prev) => {
        if (message?.chunk_number === 1) {
          return prev.length > 0 && !prev[prev.length - 1]?.resp_id
            ? prev.map((msg, index) =>
                index === prev.length - 1
                  ? {
                      ...msg,
                      resp_id: message.message_id,
                      response: message.chunk,
                    }
                  : msg
              )
            : prev;
        } else if (message?.chunk) {
          return prev.map((msg) =>
            msg?.resp_id === message.message_id
              ? { ...msg, response: (msg.response ?? "") + message.chunk }
              : msg
          );
        } else if (message?.filtered_documents) {
          return prev.map((msg) =>
            msg?.resp_id === message.message_id
              ? { ...msg, docs: message.filtered_documents }
              : msg
          );
        } else if (
          message?.sql_statement ||
          message?.sql_result ||
          message?.chart
        ) {
          return prev.map((msg) =>
            msg?.resp_id === message.message_id
              ? {
                  ...msg,
                  chart: {
                    sql_statement: message.sql_statement,
                    sql_result: JSON.parse(message?.sql_result),
                    chart: message?.chart,
                    chart_title:
                      message?.chart_title?.replace(/["`]/g, "") ?? "",
                    chart_description:
                      message?.chart_description?.replace(/["`]/g, "") ?? "",
                  },
                  anomaly: message?.anomaly,
                  data_product: message?.data_product,
                }
              : msg
          );
        }
        if (message?.anomaly) {
          return prev.map((msg) =>
            msg?.resp_id === message.message_id
              ? {
                  ...msg,
                  anomaly: message?.anomaly,
                  data_product: message?.data_product,
                }
              : msg
          );
        }
        if (message?.similar_questions) {
          return prev.map((msg) =>
            msg?.resp_id === message.message_id
              ? {
                  ...msg,
                  similar_questions: message.similar_questions?.match(
                    /(?<=\d+\.\s)(.*?)(?=\n\s*\d+\.|$)/gs
                  ),
                }
              : msg
          );
        }

        return prev;
      });
    };

    //   this.ws.onclose = () => {
    //     console.log("WebSocket connection closed. Attempting to reconnect...");
    //     this.reconnect(setMessages);
    //   };
  }

  checkConnection() {
    if (this.ws.readyState !== WebSocket.OPEN) {
      return false;
    } else {
      return true;
    }
  }

  reconnect(setMessages) {
    this.ws = new WebSocket(
      `${process.env.REACT_APP_HAYA_WS_ADDRESS}/haya/ws/stream`
    );
    this.init(setMessages);
  }

  sendMessage(prompt, setMessages, userId) {
    // Check if WebSocket is open before sending the message
    if (this.ws.readyState === WebSocket.CONNECTING) {
      console.log("WebSocket is still connecting. Message will be queued.");
      this.ws.onopen = () => this.sendMessage(prompt, setMessages);
      return;
    } else if (this.ws.readyState !== WebSocket.OPEN) {
      console.log("WebSocket is not open. Reconnecting...");
      this.reconnect(setMessages);
      this.ws.onopen = () => this.sendMessage(prompt, setMessages);
      return;
    }

    if (!(prompt && prompt !== "")) {
      return;
    }

    setMessages((prev) => [...prev, { timestamp: Date.now(), query: prompt }]);

    this.ws.send(
      JSON.stringify({
        userId: userId,
        app_id: "audyt",
        query: prompt,
        collection_name: "audyt",
        session_id: "234587",
      })
    );
  }
}
